/* libs/contractManagement/src/Views/Dialogs/DigitalSignDialog/DigitalSignDialogView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host .formFieldItem {
  max-width: 40%;
}
:host legend {
  font-size: 12px;
  font-weight: unset;
  margin-bottom: 5px;
}
:host fieldset {
  margin-bottom: 8px;
}
:host .mat-mdc-form-field.warning ::ng-deep .mat-form-field-appearance-outline,
:host .mat-mdc-form-field.warning ::ng-deep .mat-mdc-form-field-error {
  color: #ff7d00;
}
:host .mat-mdc-form-field.warning.mat-form-field-invalid ::ng-deep mat-label {
  color: #ff7d00;
}
/*# sourceMappingURL=DigitalSignDialogView.css.map */
