/* libs/feedback/src/Views/Widgets/FeedbackWidgetView.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host .feedback-button {
  background-color: #f03e3e;
  color: #ffffff;
}
/*# sourceMappingURL=FeedbackWidgetView.css.map */
