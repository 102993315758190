<form [formGroup]="confirmationForm"
      (submit)="saveForm()">
    <div fxLayout="column"
         fxLayout.gt-sm="row">
        <mat-checkbox formControlName="proofOfPaymentConfirmation"
                      [attr.test-id]="'proofOfPaymentConfirmation'">
            <span class="radio-label">{{'Bezahltnachweis bestätigt' | translate}}</span>
        </mat-checkbox>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end"
         fxLayoutGap="16px">
        <button mat-flat-button
                [color]="'accent'"
                *ngIf="!isReadonlyUser"
                type="submit"
                mat-flat-button
                [color]="'accent'"
                [attr.test-id]="'SaveInvoiceButton'"
                [disabled]="!confirmationForm.valid">
            {{ 'global.save' | translate }}
        </button>
    </div>
</form>
