/* libs/contractManagement/src/Views/ContractOverviewAcknowledgementView.scss */
:host {
  display: block;
  padding-top: 16px;
}
:host .mat-mdc-radio-button {
  margin-right: 24px;
}
:host .strikethrough {
  text-decoration-line: line-through;
}
:host .ready-container l7-text-block {
  color: #5e6f79;
}
:host .ready-container mat-icon {
  color: #54ed9c;
}
/*# sourceMappingURL=ContractOverviewAcknowledgementView.css.map */
