// #region Imports

import {
    AcknowledgementService, ContractCalculationCrudService,
    ContractsDocumentService,
    IAcknowledgementDto, IContractDocumentDto,
    IDocumentTypeDto,
    IInsuranceAndHandlingDto,
    IObjectValueChangeCalculationDto,
    IPurchaseEntryDto,
    PurchaseEntryFunctionService,
} from '@abcfinlab/api/contract';
import {
    ContractsDeprecatedService,
    IContractManagementDetailsDto, ILinkDto,
    SigningLinkAcknowledgeService,
} from '@abcfinlab/api/global';
import { isUserInfoLogins, UserService } from '@abcfinlab/auth';
import { convertToISODate, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ContractOverviewTakeoverConfirmationUploadDialogView,
} from 'libs/contractManagement/src/Views/Dialogs/ContractOverviewTakeoverConfirmationUploadDialogView';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UploadDocumentType } from '../../../../apps/l7/src/app/models/enums/UploadDocumentType.enum';
import { IContractManagementOverviewInfo } from '../Models/IContractManagementOverviewInfo';

// #endregion

type AcknowledgementInfo = { acknowledgement: IAcknowledgementDto }
    & { details: IContractManagementDetailsDto }
    & { link?: ILinkDto }
    & { purchaseEntry: Partial<IPurchaseEntryDto> }
    & { expenses: IInsuranceAndHandlingDto };

/**
 * The presenter of the {@link ContractOverviewAcknowledgementView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class ContractOverviewAcknowledgementViewPresenter {

    // #region Fields
    private _acknowledgementDataSubscription: Subscription;
    private readonly _contractCalculationService: ContractCalculationCrudService;
    private readonly _contractsDeprecatedService: ContractsDeprecatedService;
    private readonly _purchaseEntryService: PurchaseEntryFunctionService;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _signingLinkAcknowledgementService: SigningLinkAcknowledgeService;
    private readonly _contractsDocumentService: ContractsDocumentService;
    private readonly _userService: UserService;

    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _matDialog: MatDialog;
    private readonly _messageBoxService: MessageBoxService;

    private _originalValues: AcknowledgementInfo;
    private readonly _data = new BehaviorSubject<AcknowledgementInfo>(null);
    private readonly _quoteId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _contractNumber: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private readonly _hasPurchasePriceChanges = new BehaviorSubject<boolean>(false);
    private readonly _hasFirstInstalment = new BehaviorSubject<boolean>(false);
    private readonly _hasLastInstalment = new BehaviorSubject<boolean>(false);
    private readonly _hasDownPayment = new BehaviorSubject<boolean>(false);
    private readonly _hasResidualValue = new BehaviorSubject<boolean>(false);
    private readonly _hasAnyInstalment = new BehaviorSubject<boolean>(false);
    private readonly _hasChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _hasAcknowledgement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _uploadedPaperSigning: BehaviorSubject<IContractDocumentDto> = new BehaviorSubject<IContractDocumentDto>(null);
    private readonly _ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _calculationValues: BehaviorSubject<IObjectValueChangeCalculationDto> = new BehaviorSubject<IObjectValueChangeCalculationDto>(null);

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewAcknowledgementViewPresenter` class.
     *
     * @public
     */
    public constructor(translationFacade: TranslationFacade, contractCalculationService: ContractCalculationCrudService,
        contractsDeprecatedService: ContractsDeprecatedService, acknowledgementService: AcknowledgementService,
        signingLinkAcknowledgeService: SigningLinkAcknowledgeService, purchaseEntryService: PurchaseEntryFunctionService,
        busyBoxService: BusyBoxService, toastService: ToastService,
        contractsDocumentService: ContractsDocumentService, userService: UserService,
        matDialog: MatDialog, messageBoxService: MessageBoxService) {
        this._contractCalculationService = contractCalculationService;
        this._contractsDeprecatedService = contractsDeprecatedService;
        this._acknowledgementService = acknowledgementService;
        this._purchaseEntryService = purchaseEntryService;
        this._signingLinkAcknowledgementService = signingLinkAcknowledgeService;
        this._contractsDocumentService = contractsDocumentService;
        this._userService = userService;

        this._translationFacade = translationFacade;
        this._busyBoxService = busyBoxService;
        this._toastService = toastService;
        this._matDialog = matDialog;
        this._messageBoxService = messageBoxService;
    }

    // #endregion

    // #region Properties
    /**
     * Returns the `originalValues` property.
     *
     * @public
     * @readonly
     */
    public get originalValues(): AcknowledgementInfo {
        return this._originalValues;
    }

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<AcknowledgementInfo> {
        return this._data.asObservable();
    }

    /**
     * Returns the `hasPurchasePriceChanges` property.
     *
     * @public
     * @readonly
     */
    public get hasPurchasePriceChanges(): Observable<boolean> {
        return this._hasPurchasePriceChanges.asObservable();
    }

    /**
     * Returns the `hasFirstInstalment` property.
     *
     * @public
     * @readonly
     */
    public get hasFirstInstalment(): Observable<boolean> {
        return this._hasFirstInstalment.asObservable();
    }

    /**
     * Returns the `hasLastInstalment` property.
     *
     * @public
     * @readonly
     */
    public get hasLastInstalment(): Observable<boolean> {
        return this._hasLastInstalment.asObservable();
    }

    /**
     * Returns the `hasDownPayment` property.
     *
     * @public
     * @readonly
     */
    public get hasDownPayment(): Observable<boolean> {
        return this._hasDownPayment.asObservable();
    }

    /**
     * Returns the `hasResidualValue` property.
     *
     * @public
     * @readonly
     */
    public get hasResidualValue(): Observable<boolean> {
        return this._hasResidualValue.asObservable();
    }

    /**
     * Returns the `hasAnyInstalment` property.
     *
     * @public
     * @readonly
     */
    public get hasAnyInstalment(): Observable<boolean> {
        return this._hasAnyInstalment.asObservable();
    }

    /**
     * Returns the `hasAnyInstalment` property.
     *
     * @public
     * @readonly
     */
    public get hasAcknowledgement(): Observable<boolean> {
        return this._hasAcknowledgement.asObservable();
    }

    /**
     * Returns the `hasChanges` property.
     *
     * @public
     * @readonly
     */
    public get hasChanges(): Observable<boolean> {
        return this._hasChanges.asObservable();
    }

    /**
     * Returns the `uploadedPaperSigning` property.
     *
     * @public
     * @readonly
     */
    public get uploadedPaperSigning(): Observable<IContractDocumentDto> {
        return this._uploadedPaperSigning.asObservable();
    }

    /**
     * Returns the `ready` property.
     *
     * @public
     * @readonly
     */
    public get ready(): Observable<boolean> {
        return this._ready.asObservable();
    }

    /**
     * Returns the `calculationValues` property.
     *
     * @public
     * @readonly
     */
    public get calculationValues(): Observable<IObjectValueChangeCalculationDto> {
        return this._calculationValues.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(rowData: IContractManagementOverviewInfo): void {
        this._quoteId.next(rowData.quote_id);
        this._contractNumber.next(rowData.contract_number);
        this._acknowledgementDataSubscription = combineLatest([
            this._contractsDeprecatedService.getContractDetails({ contractNumber: rowData.contract_number }).pipe(take(1), catchError(() => of(null))),
            this._acknowledgementService.readByContractNumber({ contractNumber: rowData.contract_number }).pipe(take(1), catchError(() => of(null))),
            this._purchaseEntryService.getPurchaseEntryForQuoteId({ quoteId: rowData.quote_id }).pipe(take(1), catchError(() => of(null))),
            this._signingLinkAcknowledgementService.getSigningLinkInfo({ leasingQuoteId: rowData.quote_id }).pipe(take(1), catchError(() => of(null))),
            this._contractCalculationService.getExpenses({ contractId: rowData.contract_number }).pipe(take(1), catchError(() => of(null))),
        ]).subscribe(([details, acknowledgement, purchaseEntry, link, expenses]) => {
            this._originalValues = {
                details,
                acknowledgement,
                purchaseEntry,
                link,
                expenses,
            };
            // id the serial_number initial is null the üb was never saved.
            if (acknowledgement?.serial_number && acknowledgement?.purchase_price_changed) {
                this.onPurchasePriceChanged(acknowledgement.purchase_price);
                this._hasChanges.next(true);
            }

            if (link) {
                const isExpired = new Date() >= new Date(link.expires_at);
                if (isExpired && !link.invalid_at) {
                    link.invalid_at = link.expires_at;
                }
            }

            if (!acknowledgement?.purchase_price) {
                acknowledgement.purchase_price = details.object.value;
            } else {
                // the purchase price was manually entered.
                // then we need to check the visibility of the installment fields
                this._hasFirstInstalment.next(acknowledgement.first_instalment ? true : false);
                this._hasLastInstalment.next(acknowledgement.last_instalment ? true : false);
                this._hasDownPayment.next(acknowledgement.down_payment ? true : false);
                this._hasResidualValue.next(acknowledgement.residual_value ? true : false);
                this._hasAnyInstalment.next(
                    this.originalValues.acknowledgement.purchase_price !== this.originalValues.details.object.value && this.originalValues.acknowledgement.purchase_price !== null,
                );
            }
            this._data.next({
                ...this._data.getValue(),
                ...{
                    details: details,
                    acknowledgement: acknowledgement,
                    purchaseEntry: purchaseEntry,
                    link: link,
                    expenses: expenses,
                },
            });
        });
    }

    /**
     * @internal
     */
    public onPurchasePriceChanged(purchasePrice: number): void {
        if (purchasePrice) {
            this._acknowledgementService.calculateByContractNumber({
                contractNumber: this._contractNumber.getValue(),
                objectValue: purchasePrice,
            }).pipe(untilDestroyed(this)).subscribe((x) => {
                this._calculationValues.next(x);
                this._hasFirstInstalment.next(x.first_instalment ? true : false);
                this._hasLastInstalment.next(x.last_instalment ? true : false);
                this._hasDownPayment.next(x.down_payment ? true : false);
                this._hasResidualValue.next(x.residual_value ? true : false);
                this._hasPurchasePriceChanges.next(purchasePrice !== this.originalValues.details.object.value);
                this._hasAnyInstalment.next(x.instalment !== null && purchasePrice !== this.originalValues.details.object.value);
            });
        } else {
            this._calculationValues.next({
                first_instalment: null,
                instalment: null,
                last_instalment: null,
                down_payment: null,
                residual_value: null,
                insurance_value: null,
            });

            this._hasFirstInstalment.next(false);
            this._hasLastInstalment.next(false);
            this._hasDownPayment.next(false);
            this._hasResidualValue.next(false);
            this._hasPurchasePriceChanges.next(false);
            this._hasAnyInstalment.next(false);
        }
    }

    /**
     * @internal
     */
    public onGenerateLink(): void {
        this._signingLinkAcknowledgementService.createSigningLink({ leasingQuoteId: this._data.getValue().acknowledgement.leasing_quote_id })
            .pipe(untilDestroyed(this)).subscribe((link) => {
                // update the link
                this._data.next({
                    ...this._data.getValue(),
                    ...{
                        link,
                    },
                });

                this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.signing_link.generated'), 'success');
            });
    }

    /**
     * @internal
     */
    public onDeactivateLink(): void {
        this._messageBoxService.show('Sind sie sicher?', 'Wenn Sie den Link deaktivieren kann er nicht mehr genutzt werden. Informieren Sie ggf. Ihre Nutzer. Sie können anschließend einen neuen Link erstellen.', MessageBoxButton.OKCancel, {
            id: 'ContractOverviewTakeoverConfirmationDeactivateLinkMessageDialogView',
            width: { maxValue: '600px' },
            labels: {
                ok: 'Deaktivieren',
                cancel: 'Abbrechen',
            },
        }).pipe(untilDestroyed(this)).subscribe((result) => {
            if (result === MessageBoxResult.OK) {
                this._signingLinkAcknowledgementService.invalidateSigningLink({ leasingQuoteId: this._data.getValue().acknowledgement.leasing_quote_id })
                    .pipe(untilDestroyed(this)).subscribe(() => {
                        // update the link
                        this._data.next({
                            ...this._data.getValue(),
                            ...{
                                link: {
                                    ...this._data.getValue().link,
                                    ...{
                                        invalid_at: new Date(Date.now()).toISOString(),
                                    },
                                },
                            },
                        });

                        this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.signing_link.deactivated'), 'success');
                    });
            }
        });
    }

    /**
     * @internal
     */
    public onPaperSigning(): void {
        this._busyBoxService.show(
            '',
            this._translationFacade.translate('global.busy'),
            this._acknowledgementService.readByContractNumber({ contractNumber: this._contractNumber.getValue() }),
        ).pipe(untilDestroyed(this)).subscribe((x) => {
            if (x.serial_number && x.acknowledgement_date && x.purchase_price && x.year_of_construction) {
                this._matDialog.open(ContractOverviewTakeoverConfirmationUploadDialogView, {
                    data: { acknowledgementDate: x.acknowledgement_date },
                    id: 'ContractOverviewTakeoverConfirmationUploadDialogView',
                    maxWidth: '600px',
                }).afterClosed().pipe(untilDestroyed(this)).subscribe((result) => {
                    if (result && !result.canceled) {
                        (result.files as Array<File>).forEach((file) => {
                            this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._contractsDocumentService.uploadDocument({
                                contractNumber: this._contractNumber.getValue(),
                                documentType: IDocumentTypeDto.AcknowledgementSignedPaper,
                                body: {
                                    file: file,
                                },
                            })).pipe(untilDestroyed(this)).subscribe((x) => {
                                this._hasAcknowledgement.next(!this._hasAcknowledgement.value);

                                this._userService.userInfo.pipe(untilDestroyed(this)).subscribe((user) => {
                                    if (isUserInfoLogins(user)) {
                                        this._uploadedPaperSigning.next({
                                            id: x,
                                            name: `${this._contractNumber.getValue()}_Übernahmebestätigung_${file.name}`,
                                            type: UploadDocumentType.ACKNOWLEDGEMENT_SIGNED_PAPER,
                                            createdBy: `${user.loginsInfo.givenName} ${user.loginsInfo.familyName}`,
                                            creationDate: (new Date()).toDateString(),
                                            archiveInfo: null,
                                        });
                                    }
                                });

                                this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.upload.succsess'), 'success');
                                this._ready.next(true);
                            }, () => {
                                this._hasAcknowledgement.next(!this._hasAcknowledgement.value);
                                this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.upload.error'), 'danger');
                            });
                        });
                    }
                });
            } else {
                this._messageBoxService.show('Achtung!', 'Der Upload der Übernahmebestätigung ist nur dann möglich, wenn alle Angaben zum Objekt gespeichert sind.', MessageBoxButton.OK, {
                    width: { maxValue: '600px' },
                    labels: {
                        ok: 'Schließen',
                    },
                });
            }
        });
    }

    /**
     * @internal
     */
    public onSave(form: UntypedFormGroup): void {
        if (form.invalid) {
            this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.saved.invalid'), 'danger');
            return;
        }

        const acknowledgement = {
            ...this._data.getValue().acknowledgement,
            ...{
                serial_number: form.controls.serialNumber.value,
                acknowledgement_date: form.controls.acknowledgementDate.value,
                year_of_construction: form.controls.yearOfConstruction.value,
                purchase_price: form.controls.purchasePrice.value,
                chassis_number: form.controls.isChassisNumber.value,
                first_instalment: form.controls.firstInstalment.value,
                instalment: form.controls.instalment.value,
                last_instalment: form.controls.lastInstalment.value,
                down_payment: form.controls.downPayment.value,
                residual_value: form.controls.residualValue.value,
            },
        };
        if (acknowledgement.acknowledgement_date) {
            acknowledgement.acknowledgement_date = convertToISODate(new Date(acknowledgement.acknowledgement_date));
        }

        this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._acknowledgementService.save({ body: acknowledgement }))
            .pipe(untilDestroyed(this)).subscribe(() => {
                this._hasPurchasePriceChanges.next(false);
                this._hasChanges.next(false);
                this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.saved.success'), 'success');
                this._hasAcknowledgement.next(!this._hasAcknowledgement.value);
            }, () => {
                this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.saved.error'), 'danger');
            });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        if (this._acknowledgementDataSubscription) {
            this._acknowledgementDataSubscription.unsubscribe();
        }
    }

    // #endregion

}
