// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EditRetailerUserViewPresenter } from './EditRetailerUserViewPresenter';

// #endregion

/**
 * The `EditRetailerUserView` view.
 *
 * @public
 */
@Component({
    selector: 'edit-retialer-user-view',
    templateUrl: './EditRetailerUserView.html',
    styleUrls: ['./EditRetailerUserView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        EditRetailerUserViewPresenter,
    ],
    standalone: false,
})
export class EditRetailerUserView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: EditRetailerUserViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `EditRetailerUserView` class.
     *
     * @public
     */
    public constructor(presenter: EditRetailerUserViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `EditRetailerUserView`
     *
     * @public
     * @readonly
     */
    public get presenter(): EditRetailerUserViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
