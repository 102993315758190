<h2 mat-dialog-title>Dokumente an Abrechner zu manuellen Bearbeitung senden</h2>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px">
    <mat-card fxLayout="column">
        <mat-card-header>
            <h3 matCardTitle>{{ 'settlement.details.billing_information' | translate }}</h3>
        </mat-card-header>
        <mat-card-content>
            <div fxFlex
                 fxLayout="row wrap"
                 fxLayoutGap="16px">
                <fieldset fxLayout="column"
                          fxFlex="0 1 calc(33.33% - 16px)">
                    <legend>{{ 'settlement.details.start_date' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.data | async).contract?.startOfContract | date | na"></l7-text-block>
                </fieldset>
                <fieldset fxLayout="column"
                          fxFlex="0 1 calc(33.33% - 16px)">
                    <legend>{{ 'settlement.details.insurance' | translate }}</legend>
                    <div fxLayout="row"
                         fxLayoutGap="8px"
                         fxLayoutAlign="start center">
                        <div fxLayout="row">
                            <l7-text-block [block]="false"
                                           [text]="(('states.insurance.' + (this.data | async).calculation?.insurance_type) | translate)"></l7-text-block>
                            <l7-text-block *ngIf="(this.data | async).calculation?.insurance_type !== 'NO'"
                                           [block]="false"
                                           [text]="': '"></l7-text-block>
                            <l7-text-block *ngIf="(this.data | async).calculation?.insurance_type !== 'NO'"
                                           [block]="false"
                                           [text]="((this.data | async).calculation?.insurance_value) | currency | na"></l7-text-block>
                        </div>
                        <mat-icon *ngIf="(this.data | async).calculation?.insurance_type === 'STANDARD'"
                                  [class]="(this.data | async).calculation | insuranceTypeIcon:'color'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="(this.data | async).calculation | insuranceTypeIcon:'icon'"></mat-icon>
                    </div>
                </fieldset>
                <fieldset fxLayout="column"
                          fxFlex="0 1 calc(33.33% - 16px)">
                    <legend>{{ 'settlement.details.pre_rent' | translate }}</legend>
                    <div fxLayout="row">
                        <l7-text-block *ngIf="(this.data | async).accounting?.pre_rent === 'NEXT_MONTH_CALCULATED'"
                                       [truncate]="true"
                                       [wrap]="true"
                                       [block]="false"
                                       [text]="'states.pre_rent.' + (this.data | async).accounting?.pre_rent | translate">
                        </l7-text-block>
                        <l7-text-block *ngIf="(this.data | async).accounting?.pre_rent === 'NEXT_MONTH_MANUAL_ENTERED'"
                                       [truncate]="true"
                                       [wrap]="true"
                                       [block]="false"
                                       [text]="'states.pre_rent.' + (this.data | async).accounting?.pre_rent | translate: {value: (this.data | async).accounting?.pre_rent_value | currency}">
                        </l7-text-block>
                        <l7-text-block *ngIf="(this.data | async).accounting?.pre_rent === 'NEXT_MONTH_WITHOUT'"
                                       [truncate]="true"
                                       [block]="false"
                                       [text]="'states.pre_rent.' + (this.data | async).accounting?.pre_rent | translate">
                        </l7-text-block>
                        <l7-text-block *ngIf="(this.data | async).accounting?.pre_rent === 'FIRST_DAY_OF_THIS_MONTH'"
                                       [truncate]="true"
                                       [wrap]="true"
                                       [block]="false"
                                       [text]="'states.pre_rent.' + (this.data | async).accounting?.pre_rent | translate">
                        </l7-text-block>
                    </div>
                </fieldset>
                <fieldset fxLayout="column"
                          fxFlex="0 1 calc(33.33% - 16px)">
                    <legend>{{ 'settlement.details.old_object_value' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.data | async).row?.object?.value | currency | na"></l7-text-block>
                </fieldset>
                <fieldset fxLayout="column"
                          fxFlex="0 1 calc(33.33% - 16px)">
                    <legend>{{ 'Bearbeitungsgebühr' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.data | async).calculation?.handling_fee_value | currency | na"></l7-text-block>
                </fieldset>
                <fieldset fxLayout="column"
                          fxFlex="0 1 calc(33.33% - 16px)" *ngIf="(data | async)?.quote?.slb_code">
                    <legend *ngIf="(data | async)?.quote?.contract_type === 'KFZ' || (data | async)?.quote?.contract_type === 'TA' || (data | async)?.quote?.contract_type === 'VA'">
                        {{ 'calculation.slb_select_label' | translate }}
                    </legend>
                    <legend *ngIf="(data | async)?.quote?.contract_type === 'MKN'">
                        {{ 'calculation.smb_select_label' | translate }}
                    </legend>
                    <l7-text-block [truncate]="true"
                                   [text]="('slb.codes.new.' + (this.data | async).quote?.slb_code) | translate"></l7-text-block>
                </fieldset>
            </div>
        </mat-card-content>
    </mat-card>

    <l7-object-value-changed *ngIf="(this.data | async).hasPurchasePriceChanged"
                             [contractType]="(this.data | async).quote.contract_type"
                             [calculationValues]="(this.data | async).changedPurchasePriceValues"
                             [insuranceValues]="{insuranceType: (this.data | async).insuranceAndHandlingFee?.insurance_type, insuranceValue: (this.data | async).insuranceAndHandlingFee?.insurance_value}"
                             [slbCalculation]="(this.data | async).slb?.activeSlbResponse?.calculation"
                             [isPurchasePriceChangedInactive]="false">
    </l7-object-value-changed>

    <form [formGroup]="manualProcessingForm"
          fxLayout="column"
          fxLayoutGap="16px">
        <mat-card fxLayout="column">
            <mat-card-header>
                <h3 matCardTitle>{{ 'Empfänger-Adressen' | translate }}</h3>
            </mat-card-header>
            <mat-card-content>
                <mat-chip-listbox aria-label="Emails list"
                               [attr.test-id]="'EmailsAssistants'">
                    <mat-chip-option [selectable]="true">
                        {{emailContractAudit}}
                    </mat-chip-option>
                </mat-chip-listbox>
                <mat-card-subtitle>{{ 'Weitere Adressaten hinzuzufügen (optional)' | translate }}</mat-card-subtitle>
                <l7-email-input-with-chips [formGroup]="manualProcessingForm"
                                           (emailList)="addEmails($event)">
                </l7-email-input-with-chips>
            </mat-card-content>
        </mat-card>
        <mat-card fxLayout="column">
            <mat-card-header>
                <h3 matCardTitle>{{ 'settlement.details.billing_information' | translate }}</h3>
                <mat-card-subtitle>{{ 'Bitte geben Sie eine Grund und Anmerkung an.' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column"
                     fxFlex
                     fxLayoutGap="16px">
                    <mat-form-field appearance="fill">
                        <mat-label>Begründung(en)</mat-label>
                        <mat-select #select
                                    formControlName="reasons"
                                    multiple>
                            <mat-select-trigger>{{select.value ? ('states.manual_processing_reasons.' + select.value[0] | translate) : ''}}
                                <span *ngIf="select.value?.length > 1"
                                      style="opacity: 0.75; font-size: 0.75em;">
                                    (+{{select.value.length - 1}} {{ 'settlement.overview.filter.more' | translate }})
                                </span>
                            </mat-select-trigger>
                            <ng-container *ngIf="reasons">
                                <mat-option *ngFor="let r of reasons"
                                            [value]="r">{{'states.manual_processing_reasons.' + r | translate}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex
                                    appearance="fill">
                        <mat-label>Anmerkung</mat-label>
                        <textarea rows="3"
                                  matInput
                                  cdkTextareaAutosize
                                  formControlName="annotation_in_mail"
                                  cdkAutosizeMinRows="4"
                                  cdkAutosizeMaxRows="8"
                                  [attr.test-id]="'AnnotationText'"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row"
                    fxLayout="center end">
    <button mat-button
            color="accent"
            mat-dialog-close>Abbrechen</button>
    <button mat-flat-button
            color="accent"
            [attr.test-id]="'SendButton'"
            [disabled]="!manualProcessingForm.valid"
            (click)="this.sendToManualProcessing()">Senden</button>
</mat-dialog-actions>
