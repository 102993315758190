import { ADMINISTRATION_ROUTES } from '@abcfinlab/administration';
import { AuthorizeCallbackView, AuthorizeView, LogoutView } from '@abcfinlab/auth';
import { SETTLEMENT_ROUTES } from '@abcfinlab/backoffice';
import { CONTACTS_ROUTES } from '@abcfinlab/contacts';
import { CONTRACT_MANAGEMENT_ROUTES } from '@abcfinlab/contract-management';
import { EXPLORER_ROUTES } from '@abcfinlab/explorer';
import { PresenterView } from '@abcfinlab/layout';
import { PRESENTATION_ROUTES } from '@abcfinlab/presentation';
import { QUOTES_ROUTES } from '@abcfinlab/quote';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GWG_ROUTES } from '../../../../libs/legacy/gwg/src/lib/GwgRoutingModule';
import { ImprintComponent } from './public/pages/imprint/imprint.component';
import { PrivacyNoticeComponent } from './public/pages/privacy-notice/privacy-notice.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';

const APP_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'presentation',
        component: PresenterView,
        children: [
            ...PRESENTATION_ROUTES,
            ...ADMINISTRATION_ROUTES,
            ...CONTACTS_ROUTES,
            ...EXPLORER_ROUTES,
            ...QUOTES_ROUTES,
            ...SETTLEMENT_ROUTES,
            ...CONTRACT_MANAGEMENT_ROUTES,
            ...GWG_ROUTES,
            {
                path: '',
                redirectTo: 'explorer/dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'login',
        component: AuthorizeView,
    },
    {
        path: 'private',
        redirectTo: 'presentation',
        pathMatch: 'full',
    },
    {
        path: 'remote',
        loadChildren: () => import('./public/modules/public-remote-signing/public-remote-signing.module').then(mod => mod.PublicRemoteSigningModule),
    },
    {
        path: 'logout',
        component: LogoutView,
    },
    {
        path: 'callback',
        component: AuthorizeCallbackView,
    },
    {
        path: 'imprint',
        component: ImprintComponent,
    },
    {
        path: 'privacy-notice',
        component: PrivacyNoticeComponent,
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES, {
        scrollPositionRestoration: 'enabled',
        enableTracing: false,
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
