// #region Imports

import {
    AccountingService,
    AcknowledgementService,
    ContractCalculationCrudService,
    ContractCrudService,
    ContractFunctionService,
    ContractsDocumentService, IContractDocumentDto,
    InstalmentCalculationService,
    ISaleAndLeaseBackCodeDto,
    ISalesChannelDto,
    ISearchContractResultDto,
    SlbService,
} from '@abcfinlab/api/contract';
import {
    CreditCheckService,
    ISepaMandateInfoDto,
    ISepaMandateStatusDto,
    KeycloakUsersService, ObjectgroupService,
    QuoteService,
    SepaMandateService,
} from '@abcfinlab/api/global';
import { EventHub, monthDiff, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { ICalculationInfo } from 'libs/presentation/src/Components/object-value-changed/object-value-changed.component';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { ISettlementContractInformationInfo } from '../Models/ISettlementContractInformationInfo';
import { KnownEvents } from '../Models/KnownEvents';

// #endregion

/**
 * The presenter of the @{link InvoiceContractInformationView} view.
 *
 * @internal
 */
@Injectable()
export class InvoiceContractInformationViewPresenter {

    // #region Fields

    private readonly _eventHub: EventHub;
    private readonly _contractsService: ContractCrudService;
    private readonly _contractCalculationService: ContractCalculationCrudService;
    private readonly _contractFunctionService: ContractFunctionService;
    private readonly _instalmentCalculationService: InstalmentCalculationService;
    private readonly _contractsDocumentService: ContractsDocumentService;
    private readonly _quoteService: QuoteService;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _accountingService: AccountingService;
    private readonly _usersService: KeycloakUsersService;
    private readonly _slbService: SlbService;
    private readonly _objectGroupService: ObjectgroupService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _creditCheckService: CreditCheckService;
    private readonly _sepaMandateService: SepaMandateService;
    private readonly _toastService: ToastService;
    private readonly _dataSubject: BehaviorSubject<ISettlementContractInformationInfo | null>;
    private readonly _sepaMandateSubject: BehaviorSubject<ISepaMandateInfoDto | null>;
    private readonly _isCreditRatingExpiredSubject: BehaviorSubject<boolean>;
    private readonly _isSLBContract: BehaviorSubject<boolean>;
    private readonly _obligations = new BehaviorSubject<string>(null);
    private readonly _referenceContracts = new BehaviorSubject<Array<string>>(null);
    private readonly _changedPurchasePriceValues: BehaviorSubject<ICalculationInfo> = new BehaviorSubject<ICalculationInfo>(null);

    private _rowChangedSubscription: Subscription;
    private _extraDataForRetailerSubscription: Subscription;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `InvoiceContractInformationView` class.
     *
     * @public
     */
    public constructor(eventHub: EventHub, toastService: ToastService, contractsDocumentService: ContractsDocumentService, contractsService: ContractCrudService, quoteService: QuoteService,
        acknowledgementService: AcknowledgementService, accountingService: AccountingService, usersService: KeycloakUsersService, busyBoxService: BusyBoxService,
        translationFacade: TranslationFacade, sepaMandateService: SepaMandateService, creditCheckService: CreditCheckService, slbService: SlbService,
        contractCalculationService: ContractCalculationCrudService, contractFunctionService: ContractFunctionService, instalmentCalculationService: InstalmentCalculationService,
        objectGroupService: ObjectgroupService) {
        this._eventHub = eventHub;
        this._contractsDocumentService = contractsDocumentService;
        this._contractsService = contractsService;
        this._contractCalculationService = contractCalculationService;
        this._contractFunctionService = contractFunctionService;
        this._instalmentCalculationService = instalmentCalculationService;
        this._quoteService = quoteService;
        this._acknowledgementService = acknowledgementService;
        this._accountingService = accountingService;
        this._usersService = usersService;
        this._slbService = slbService;
        this._objectGroupService = objectGroupService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._creditCheckService = creditCheckService;
        this._sepaMandateService = sepaMandateService;
        this._toastService = toastService;
        this._rowChangedSubscription = Subscription.EMPTY;
        this._dataSubject = new BehaviorSubject(null);
        this._sepaMandateSubject = new BehaviorSubject(null);
        this._isCreditRatingExpiredSubject = new BehaviorSubject(false);
        this._isSLBContract = new BehaviorSubject<boolean>(false);
    }

    // #endregion

    // #region Properties

    public get sepaMandate(): Observable<ISepaMandateInfoDto | null> {
        return this._sepaMandateSubject.asObservable();
    }

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<ISettlementContractInformationInfo | null> {
        return this._dataSubject.asObservable();
    }

    /**
     * Returns the `isCreditRatinxpired` property.
     *
     * @public
     * @readonly
     */
    public get isCreditRatingExpired(): Observable<boolean> {
        return this._isCreditRatingExpiredSubject.asObservable();
    }

    /**
     * Returns the `isCreditRatinxpired` property.
     *
     * @public
     * @readonly
     */
    public get isSLBContract(): Observable<boolean> {
        return this._isSLBContract.asObservable();
    }

    /**
     * Returns the `obligations` property.
     *
     * @public
     * @readonly
     */
    public get obligations(): Observable<string> {
        return this._obligations.asObservable();
    }

    /**
     * Returns the `referenceContracts` property.
     *
     * @public
     * @readonly
     */
    public get referenceContracts(): Observable<Array<string>> {
        return this._referenceContracts.asObservable();
    }

    /**
     * Returns the `changedPurchasePriceValues` property.
     *
     * @public
     * @readonly
     */

    public get changedPurchasePriceValues(): Observable<ICalculationInfo> {
        return this._changedPurchasePriceValues.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._rowChangedSubscription = this._eventHub.getEvent<ISearchContractResultDto>(KnownEvents.SETTLEMENT_CHANGED).subscribe((x) => {
            this._dataSubject.next({ row: x });
            const getChangedCalculation$ = this._instalmentCalculationService.getInstalmentCalculation({ contractId: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of(null)));
            const getAcknowledgement$ = x.slb_code ? of(null) : this._acknowledgementService.readByContractNumber({ contractNumber: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of(null)));
            const hasSlb = x.slb_code === ISaleAndLeaseBackCodeDto.UnechtlnV2 || x.slb_code === ISaleAndLeaseBackCodeDto.UnechtliV2 || x.slb_code === ISaleAndLeaseBackCodeDto.Unechtlnmk || x.slb_code === ISaleAndLeaseBackCodeDto.Unechtlimk;
            const getSlb$ = hasSlb ? this._slbService.getSlb({ contractId: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of(null))) : of(null);
            const initializer = this._busyBoxService.show('', this._translationFacade.translate('global.busy'), combineLatest([
                this._contractsDocumentService.getContractsDocuments({ contractNumber: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of([]))),
                this._contractsService.read({ contractNumber: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of(null))),
                this._quoteService.getQuoteById({ quoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._quoteService.getQuoteCalculation({ leasingQuoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                getAcknowledgement$,
                this._accountingService.read({ leasingQuoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._usersService.getSalesAssistance({ quoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._contractFunctionService.getSepaMandateStatus({ contractNumber: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of(null))),
                this._creditCheckService.getCreditCheck({ quoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._usersService.getLead({ quoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._contractCalculationService.getExpenses({ contractId: this._dataSubject.value.row.nav_contract_number }).pipe(take(1), catchError(() => of(null))),
                this._quoteService.getRefinancingInterest({ quoteId: this._dataSubject.value.row.quote_id }).pipe(take(1), catchError(() => of(null))),
                getChangedCalculation$,
                getSlb$,
                this._quoteService.getQuoteByIdV({ quoteId: this._dataSubject.value.row.quote_id }).pipe(take(1),
                    concatMap(quote => forkJoin(
                        {
                            objGroup: this._objectGroupService.getObjectgroup({ code: quote.inhouseQuoteCalculation.objectGroupCode }),
                            newGetQuote: of(quote),
                        },
                    )),
                    catchError(() => of(null))),
            ]), { id: 'initializerContractInformation' })
                .subscribe(([documents, contract, quote, calculation, acknowledgement, accounting, salesAssistance, sepa, creditCheck, salesLead, insuranceAndHandlingFee, refinancingInterest, changedCalculation, slb, objGroupAndNewGetQuote]) => {
                    documents = Array.isArray(documents) ? documents : documents ? [documents] : [];

                    if (slb && slb.activeSlbResponse && slb.activeSlbResponse.calculation.totalLeasingValueNet !== calculation.total_leasing_value) {
                        this._changedPurchasePriceValues.next({
                            totalLeasingValueNet: slb.activeSlbResponse.calculation.totalLeasingValueNet,
                            lastInstalment: slb.activeSlbResponse.calculation.lastInstalment,
                            firstInstalment: slb.activeSlbResponse.calculation.firstInstalment,
                            instalment: slb.activeSlbResponse.calculation.instalment,
                            downPayment: slb.activeSlbResponse.calculation.downPayment,
                            residualValue: slb.activeSlbResponse.calculation.residualValue,
                        });
                    } else if (!slb && calculation?.total_leasing_value !== acknowledgement?.purchase_price) {
                        this._changedPurchasePriceValues.next({
                            totalLeasingValueNet: acknowledgement?.purchase_price,
                            lastInstalment: acknowledgement?.last_instalment,
                            firstInstalment: acknowledgement?.first_instalment,
                            instalment: acknowledgement?.instalment,
                            downPayment: acknowledgement?.down_payment,
                            residualValue: acknowledgement?.residual_value,
                        });
                    }
                    const data = {
                        ...this._dataSubject.value,
                        hasRegistrationCertificate: documents.some(x => x.type === 'REGISTRATION_CERTIFICATE_PART_2'),
                        quote: quote,
                        calculation: calculation,
                        acknowledgement: acknowledgement,
                        accounting: accounting,
                        salesAssistance: salesAssistance,
                        sepa: sepa,
                        creditCheck: creditCheck,
                        contract: contract,
                        salesLead: salesLead,
                        insuranceAndHandlingFee: insuranceAndHandlingFee,
                        refinancingInterest,
                        changedCalculation,
                        slb,
                        newGetQuote: objGroupAndNewGetQuote.newGetQuote,
                        objGroup: objGroupAndNewGetQuote.objGroup,
                        changedPurchasePriceValues: this._changedPurchasePriceValues.getValue(),
                        hasPurchasePriceChanged: this._changedPurchasePriceValues.getValue() !== null && this._changedPurchasePriceValues.getValue().totalLeasingValueNet !== null ? true : false,
                    };

                    this._dataSubject.next(data);
                    this._sepaMandateSubject.next(sepa);
                    this._isCreditRatingExpiredSubject.next(this.calculateIsCreditRatingExpired(new Date(creditCheck?.timestamp)));
                    this._isSLBContract.next(quote?.slb);
                    if (quote?.sales_channel === ISalesChannelDto.Retailer) {
                        this._extraDataForRetailerSubscription = this._contractFunctionService.getObligationsForQuote({ quoteId: this._dataSubject.value.row.quote_id, contractNumber: this._dataSubject.value.row.nav_contract_number })
                            .subscribe((o) => {
                                if (o) {
                                    let obligationsText: string = '';
                                    o.forEach(obligation => obligationsText += `${obligation.description}, `);
                                    this._obligations.next(obligationsText.replace(/,([^,]*)$/, '$1'));
                                }
                            });
                    }
                    this._referenceContracts.next(contract.referenceContracts);
                    this._eventHub.publish<ISettlementContractInformationInfo>(KnownEvents.SETTLEMENT_DETAIL_LOADED, data);
                }, () => {
                }, () => {
                    initializer.unsubscribe();
                });
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._rowChangedSubscription.unsubscribe();
        this._extraDataForRetailerSubscription?.unsubscribe();
    }

    /**
     * @public
     */
    public createSepaMandate(): void {
        const subscription = this._busyBoxService.show('', this._translationFacade.translate('global.busy'),
            this._sepaMandateService.patchViaContract({ contractId: this._dataSubject.value.row.nav_contract_number, body: { status: ISepaMandateStatusDto.Active } }),
        ).subscribe((x) => {
            this._sepaMandateSubject.next(x);
        }, () => {
            this._toastService.show(this._translationFacade.translate('error.sepa_not_created'), 'danger');
        }, () => {
            subscription.unsubscribe();
        });
    }

    /**
     * @private
     */
    private calculateIsCreditRatingExpired(date: Date): boolean {
        const indicator = 12; // the indicator is twelve month.
        const now = new Date(Date.now());
        const offset = monthDiff(date, now);

        return offset >= indicator ? true : false;
    }

    // #endregion

}
