/* libs/administration/src/Views/EditRetailerView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .clear-all-container {
  margin: 16px 0;
}
:host .quick-action-container {
  margin: 16px 0;
}
/*# sourceMappingURL=EditRetailerView.css.map */
