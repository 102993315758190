/* libs/backoffice/src/Views/invoice-form.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host .first-row {
  margin-bottom: 15px;
  justify-content: space-between;
}
:host .file {
  background: transparent;
  border: none;
  cursor: pointer;
}
:host *,
:host *:focus,
:host *:hover,
:host *:active {
  outline: none;
}
:host .form-title {
  font-size: 16px;
  color: #5e6f79;
}
:host form {
  padding-right: 10px;
  height: 70vh !important;
}
:host form .mdc-icon-button {
  display: flex;
}
:host form .mat-mdc-form-field.purple-rain ::ng-deep .mat-mdc-input-element {
  caret-color: #8028bf;
}
:host form .mat-mdc-form-field.purple-rain ::ng-deep .mdc-floating-label,
:host form .mat-mdc-form-field.purple-rain ::ng-deep .mat-mdc-icon-button {
  color: #8028bf;
}
:host form .mat-mdc-form-field.purple-rain ::ng-deep .mdc-line-ripple::after,
:host form .mat-mdc-form-field.purple-rain ::ng-deep .mdc-line-ripple::before {
  border-bottom-color: #8028bf;
}
:host form .mat-mdc-form-field.no-value .mat-mdc-input-element,
:host form .mat-mdc-form-field.warning ::ng-deep .mat-mdc-input-element {
  caret-color: #ffa800 !important;
}
:host form .mat-mdc-form-field.no-value .mdc-floating-label,
:host form .mat-mdc-form-field.no-value .mat-mdc-icon-button,
:host form .mat-mdc-form-field.no-value .mat-mdc-form-field-error,
:host form .mat-mdc-form-field.warning ::ng-deep .mdc-floating-label,
:host form .mat-mdc-form-field.warning ::ng-deep .mat-mdc-icon-button,
:host form .mat-mdc-form-field.warning ::ng-deep .mat-mdc-form-field-error {
  color: #ffa800 !important;
}
:host form .mat-mdc-form-field.no-value .mdc-line-ripple::after,
:host form .mat-mdc-form-field.no-value .mdc-line-ripple::before,
:host form .mat-mdc-form-field.warning ::ng-deep .mdc-line-ripple::after,
:host form .mat-mdc-form-field.warning ::ng-deep .mdc-line-ripple::before {
  border-bottom-color: #ffa800 !important;
}
:host form .mat-mdc-form-field.mat-form-field-invalid ::ng-deep .mat-mdc-input-element {
  caret-color: #ad0149;
}
:host form .mat-mdc-form-field.mat-form-field-invalid ::ng-deep .mdc-floating-label,
:host form .mat-mdc-form-field.mat-form-field-invalid ::ng-deep .mat-mdc-icon-button,
:host form .mat-mdc-form-field.mat-form-field-invalid ::ng-deep .mat-mdc-form-field-error {
  color: #ad0149;
}
:host form .mat-mdc-form-field.mat-form-field-invalid ::ng-deep .mdc-line-ripple::after,
:host form .mat-mdc-form-field.mat-form-field-invalid ::ng-deep .mdc-line-ripple::before {
  border-bottom-color: #ad0149;
}
:host form {
  overflow: hidden;
  overflow-y: scroll;
  padding: 16px;
}
/*# sourceMappingURL=invoice-form.component.css.map */
