// #region Imports

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    RETAILER_CONFIGURATION_ROUTE,
    RETAILER_EDIT_ROUTE,
    RETAILER_OVERVIEW_ROUTE,
    RETAILER_USER_EDIT_ROUTE,
} from '../Routing/Routes';

// #endregion

/**
 * @public
 */
export const ADMINISTRATION_ROUTES: Routes = [
    RETAILER_OVERVIEW_ROUTE,
    RETAILER_EDIT_ROUTE,
    RETAILER_USER_EDIT_ROUTE,
    RETAILER_CONFIGURATION_ROUTE,
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(ADMINISTRATION_ROUTES),
    ],
    exports: [RouterModule],
})
export class AdministrationRoutingModule {
}
