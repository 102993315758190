import { IContractWorkflowFirstOpenTypeDto, ISearchRetailerContractResultDto, RetailerContractService } from '@abcfinlab/api/contract';
import { Globals, arrayEquals, once } from '@abcfinlab/core';
import { BusyBoxService, FilterCondition, FilterConditionComparer } from '@abcfinlab/ui';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { IRetailerContractManagementSearchCondition } from '../../../Models/IRetailerContractManagementSearchCondition';
import { CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH } from '../../../Routing/RoutePaths';

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: FilterCondition<IRetailerContractManagementSearchCondition> = {
    term: '',
    firstOpenType: [],
};

/**
 * @private
 */
const isEmptySearchCondition: FilterConditionComparer<IRetailerContractManagementSearchCondition> = other => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase()
    && arrayEquals(EMPTY_SEARCH_CONDITION.firstOpenType, other.firstOpenType);

@UntilDestroy()
@Injectable()
export class RetailerContractsOverviewViewPresenter {

    private readonly _busyBoxService: BusyBoxService;
    private readonly _retailerContractService: RetailerContractService;
    private readonly _dataSource: MatTableDataSource<ISearchRetailerContractResultDto>;
    private readonly _dataSourceTotalSubject: BehaviorSubject<number>;
    private readonly _pageSizeSubject: BehaviorSubject<number>;
    private readonly _pageIndexSubject: BehaviorSubject<number>;
    private readonly _pageSizesSubject: BehaviorSubject<Array<number>>;
    private readonly _usersTableColumns: Array<string>;
    private readonly _router: Router;
    private readonly _route: ActivatedRoute;
    private readonly _location: Location;
    private readonly _conditionSubject: BehaviorSubject<FilterCondition<IRetailerContractManagementSearchCondition>>;
    private readonly _defaultConditionSubject: BehaviorSubject<FilterCondition<IRetailerContractManagementSearchCondition>>;
    private readonly _conditionComparerSubject: BehaviorSubject<FilterConditionComparer<IRetailerContractManagementSearchCondition>>;
    private readonly _isDefaultSearchConditionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private readonly _firstOpenTypeSubject: BehaviorSubject<Array<IContractWorkflowFirstOpenTypeDto>>;

    public constructor(retailerContractService: RetailerContractService, router: Router, route: ActivatedRoute, busyBoxService: BusyBoxService, location: Location) {
        this._retailerContractService = retailerContractService;
        this._busyBoxService = busyBoxService;
        this._router = router;
        this._route = route;
        this._location = location;
        this.retailerContractsList$ = new BehaviorSubject([]);
        this._dataSourceTotalSubject = new BehaviorSubject(0);
        this._pageSizeSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZE);
        this._pageIndexSubject = new BehaviorSubject(0);
        this._pageSizesSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZES);
        this._defaultConditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._conditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._conditionComparerSubject = new BehaviorSubject(isEmptySearchCondition);
        this._firstOpenTypeSubject = new BehaviorSubject<Array<IContractWorkflowFirstOpenTypeDto>>([
            IContractWorkflowFirstOpenTypeDto.UploadInvoice,
            IContractWorkflowFirstOpenTypeDto.Acknowledgement,
            IContractWorkflowFirstOpenTypeDto.RequestPayment,
            IContractWorkflowFirstOpenTypeDto.AllCompleted,
        ]);
        this._dataSource = new MatTableDataSource();
        this._usersTableColumns = ['lesseeName', 'contractNumber', 'objectDescription', 'signingDate', 'contractType', 'state'];
    }

    public get retailerContracts(): Observable<Array<ISearchRetailerContractResultDto>> {
        return this.retailerContractsList$.asObservable();
    }

    public get columns(): Array<string> {
        return this._usersTableColumns;
    }

    public retailerContractsList$: BehaviorSubject<Array<ISearchRetailerContractResultDto>>;
    public searchString: string = '';

    /**
     * Returns the `defaultCondition` property.
     *
     * @public
     * @readonly
     */
    public get defaultCondition(): Observable<FilterCondition<IRetailerContractManagementSearchCondition>> {
        return this._defaultConditionSubject.asObservable();
    }

    /**
     * Returns the `condition` property.
     *
     * @public
     * @readonly
     */
    public get condition(): Observable<FilterCondition<IRetailerContractManagementSearchCondition>> {
        return this._conditionSubject.asObservable();
    }

    /**
     * Returns the `conditionComparer` property.
     *
     * @public
     * @readonly
     */
    public get conditionComparer(): Observable<FilterConditionComparer<IRetailerContractManagementSearchCondition>> {
        return this._conditionComparerSubject.asObservable();
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    /**
     * Returns the `firstOpenType` property.
     *
     * @public
     * @readonly
     */
    public get firstOpenType(): Observable<Array<IContractWorkflowFirstOpenTypeDto>> {
        return this._firstOpenTypeSubject.asObservable();
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<ISearchRetailerContractResultDto> {
        return this._dataSource;
    }

    /**
     * Returns the `dataSourceTotal` property.
     *
     * @public
     * @readonly
     */
    public get dataSourceTotal(): Observable<number> {
        return this._dataSourceTotalSubject.asObservable();
    }

    /**
     * Returns the `pageSize` property.
     *
     * @public
     * @readonly
     */
    public get pageSize(): Observable<number> {
        return this._pageSizeSubject.asObservable();
    }

    /**
     * Returns the `pageIndex` property.
     *
     * @public
     * @readonly
     */
    public get pageIndex(): Observable<number> {
        return this._pageIndexSubject.asObservable();
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    // #region Methods

    /**
     * @internal
     */
    public initialize(): void {
        // check query params first for the filter
        once(this._route.queryParams, (x) => {
            this._conditionSubject.next({
                ...this._conditionSubject.value,
                firstOpenType: x.condition ? Array.isArray(x.condition) ? x.condition : [x.condition] : [],
            });
        });

        this.searchContracts(0, Globals.Page.DEFAULT_PAGE_SIZE);
    }

    /**
     * @internal
     */
    public onPageChanged(page: PageEvent): void {
        this._pageSizeSubject.next(page.pageSize);
        this.searchContracts(page.pageIndex, page.pageSize);
    }

    /**
     * @internal
     */
    public onTermChanged(searchValue: string): void {
        this._conditionSubject.next({
            ...this._conditionSubject.value,
            term: searchValue.trim().toLowerCase(),
        });
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this.searchContracts(0, Globals.Page.DEFAULT_PAGE_SIZE);
    }

    /**
     * @internal
     */
    public onConditionChanged(firstOpenType: Array<IContractWorkflowFirstOpenTypeDto>): void {
        this._conditionSubject.next({
            ...this._conditionSubject.value,
            firstOpenType: firstOpenType,
        });
        this._location.replaceState(this._router.createUrlTree([], { queryParams: { condition: firstOpenType.join(',') } }).toString());

        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this.searchContracts(0, this._pageSizeSubject.value);
    }

    /**
     * @internal
     */
    public onResetConditions(): void {
        this._conditionSubject.next(EMPTY_SEARCH_CONDITION);
        this._location.replaceState(this._router.createUrlTree([], { queryParams: {} }).toString());
        this._isDefaultSearchConditionSubject.next(true);
        this.searchContracts(0, this._pageSizeSubject.value);
    }

    /**
     * @internal
     */
    public showContractDetails(targetContract: ISearchRetailerContractResultDto): void {
        void this._router.navigate([`../../../${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}`, targetContract.contractNumber], { relativeTo: this._route });
    }

    /**
     * @private
     */
    private searchContracts(page: number, pageSize: number): void {
        once(this._busyBoxService.show(undefined, undefined,
            this._retailerContractService.search({
                searchString: this._conditionSubject.value.term,
                page: page,
                pageSize: pageSize,
                firstOpenType: this._conditionSubject.value.firstOpenType,
            })),
        (x) => {
            this.retailerContractsList$.next(x.content);
            this._dataSource.data = x.content;
            this._dataSourceTotalSubject.next(x.totalElements);
            this._pageIndexSubject.next(page);
        });
    }

    // #endregion

}
