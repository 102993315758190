/* libs/contractManagement/src/Views/retailer-contract-managment/retailer-contract-details-view/RetailerContractDetailsView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host l7-text-block.small-text ::ng-deep p {
  font-size: 12px;
}
:host legend {
  font-size: 12px;
  font-weight: unset;
  margin-bottom: 5px;
}
:host fieldset {
  margin-bottom: 8px;
}
:host mat-card-header ::ng-deep .mat-mdc-card-header-text {
  width: 100%;
}
:host .block-with-margin {
  margin-left: 20px;
}
:host mat-list-item .mdc-list-item__content {
  align-self: normal;
}
:host mat-list-item .retailerTask:hover {
  cursor: pointer;
}
:host mat-list-item.item-disabled mat-card,
:host mat-list-item.item-disabled mat-icon {
  color: rgba(48, 59, 69, 0.38);
}
:host .mat-mdc-menu-trigger {
  cursor: pointer;
}
:host .contract-state {
  height: 14px;
  font-size: 14px;
}
:host .contract-state-PENDING {
  color: #8028bf !important;
  background-color: rgba(128, 40, 191, 0.2) !important;
}
:host .contract-state-PENDING mat-icon,
:host .contract-state-PENDING l7-text-block {
  color: #8028bf !important;
}
:host .contract-state-OPEN {
  color: #1b7e9a !important;
  background-color: #e4f0f3 !important;
}
:host .contract-state-OPEN mat-icon,
:host .contract-state-OPEN l7-text-block {
  color: #1b7e9a !important;
}
:host .contract-state-COMPLETED {
  color: #02cc83 !important;
  background-color: rgba(30, 188, 131, 0.2) !important;
}
:host .contract-state-COMPLETED mat-icon,
:host .contract-state-COMPLETED l7-text-block {
  color: #02cc83 !important;
}
:host .contract-state-ERROR {
  color: #ad0149 !important;
  background-color: rgba(173, 1, 73, 0.2) !important;
}
:host .contract-state-ERROR mat-icon,
:host .contract-state-ERROR l7-text-block {
  color: #ad0149 !important;
}
/*# sourceMappingURL=RetailerContractDetailsView.css.map */
