/* libs/contractManagement/src/Views/retailer-contract-managment/retailer-contracts-overview-view/retailer-contracts-overview-view.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
.contactInfo {
  color: #596068;
}
.info-row {
  margin-top: 8px;
}
.contract-state {
  height: 14px;
  font-size: 14px;
}
.contract-state-PENDING {
  background-color: rgba(128, 40, 191, 0.2) !important;
  color: #8028bf !important;
}
.contract-state-PENDING mat-icon,
.contract-state-PENDING l7-text-block {
  color: #8028bf !important;
}
.contract-state-OPEN {
  color: #1b7e9a !important;
  background-color: #e4f0f3 !important;
}
.contract-state-OPEN mat-icon,
.contract-state-OPEN l7-text-block {
  color: #1b7e9a !important;
}
.contract-state-COMPLETED {
  background-color: rgba(30, 188, 131, 0.2) !important;
  color: #02cc83 !important;
}
.contract-state-COMPLETED mat-icon,
.contract-state-COMPLETED l7-text-block {
  color: #02cc83 !important;
}
.contract-state-ERROR {
  background-color: rgba(173, 1, 73, 0.2) !important;
  color: #ad0149 !important;
}
.contract-state-ERROR mat-icon,
.contract-state-ERROR l7-text-block {
  color: #ad0149 !important;
}
.contract-state mat-icon {
  font-size: 14px;
  height: 16px;
  width: 16px;
}
.contractItem {
  margin-bottom: 24px;
}
.contractItemTitle {
  font-size: 14px;
  line-height: 14px;
}
/*# sourceMappingURL=retailer-contracts-overview-view.component.css.map */
