import {
    ContractsDocumentService,
    IContractDocumentDto,
    IContractManagementOverviewDto,
    IDocumentTypeDto,
} from '@abcfinlab/api/contract';
import { IUserInfoDto } from '@abcfinlab/api/login';
import { isUserInfoLogins, UserService } from '@abcfinlab/auth';
import { CustomFile } from '@abcfinlab/presentation';
import {
    ToastService,
} from '@abcfinlab/ui';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'l7-contract-document-upload',
    templateUrl: './contract-document-upload-view.component.html',
    styleUrls: ['./contract-document-upload-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ContractDocumentUploadViewComponent implements OnInit {

    @Input() contract: IContractManagementOverviewDto;
    @Input() documents: Array<IContractDocumentDto>;
    @Input() loading: boolean;
    @Input() canUploadFiles: boolean;
    @Input() documentType: IDocumentTypeDto;

    @Output() uploadedDocuments: EventEmitter<Array<IContractDocumentDto>> = new EventEmitter<Array<IContractDocumentDto>>();
    @Output() isReady: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    private readonly _user$: Observable<IUserInfoDto>;
    private _user: IUserInfoDto;

    public filesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public canUpload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public title: string;
    public confirmationText: string;
    public typeText: string;

    constructor(private readonly _contractsDocumentService: ContractsDocumentService, private readonly _cdr: ChangeDetectorRef, private readonly _notify: ToastService, userService: UserService) {
        this._user$ = userService.userInfo;
        this._user$.pipe(untilDestroyed(this)).subscribe((user) => {
            this._user = user;
        });
    }

    public ngOnInit(): void {
        switch (this.documentType) {
            case IDocumentTypeDto.Invoice:
                this.title = 'contract_management.invoice_upload.view_title';
                this.confirmationText = 'contract_management.invoice_upload.confirmation_text';
                this.typeText = 'Rechnung_1';
                break;
            case IDocumentTypeDto.PreVendorInvoice:
                this.title = 'contract_management.preDeliveryInvoice.view_title';
                this.confirmationText = 'contract_management.preDeliveryInvoice.confirmation_text';
                this.typeText = 'Vorlieferantenrechnung_1';
                break;
            case IDocumentTypeDto.ProofOfPayment:
                this.title = 'contract_management.proofOfPayment.view_title';
                this.confirmationText = 'contract_management.proofOfPayment.confirmation_text';
                this.typeText = 'Bezahltnachweis_1';
                break;
        }
    }

    uploadFiles(files: Array<CustomFile>) {
        this.loading = true;
        Array.from(files).forEach((file, index) => {
            this._contractsDocumentService.uploadDocument({
                contractNumber: this.contract.contract_number,
                documentType: this.documentType,
                body: {
                    file,
                },
            })
                .pipe(untilDestroyed(this))
                .subscribe((res) => {
                    this.setUploadedFiles(file, res, files.length, index);
                },
                (_error) => {
                    this.setUploadedFiles(file, null, files.length, index);
                });
        });
    }

    private setUploadedFiles(file: File, _id: string, _length: number, i: number) {
        if (_id && isUserInfoLogins(this._user)) {
            this.documents.push({
                id: _id,
                name: `${this.contract.contract_number}_${this.typeText}_${file.name}`,
                type: this.documentType,
                createdBy: `${this._user.loginsInfo.givenName} ${this._user.loginsInfo.familyName}`,
                creationDate: (new Date()).toDateString(),
                archiveInfo: null,
            });
        } else {
            this._notify.showError('Hochladen nicht erfolgreich!');
        }

        if (_length === i + 1) {
            this.loading = false;
            this.uploadedDocuments.emit(this.documents);
            this.isReady.emit([this.contract.contract_number]);
            this._cdr.detectChanges();
        }
    }

    filesToUpload(files: Array<File>): void {
        this.filesCount$.next(files.length);

        this.canUpload$.next(files.length === 1);
    }

}
