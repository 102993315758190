// #region Imports

import { Injectable } from '@angular/core';

// #endregion

/**
 * The presenter of the {@link FeedbackWidgetView} view.
 *
 * @internal
 */
@Injectable()
export class FeedbackWidgetViewPresenter {

    // #region Fields
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `FeedbackWidgetViewPresenter` class.
     *
     * @public
     */
    public constructor() {
    }

    // #endregion

    // #region Properties
    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @protected
     */
    public onFeedback(): void {
        // dirty hack to open the feedback widget
        const container = document.getElementById('feedback-button') as HTMLIFrameElement;
        const button = container.contentWindow.document.getElementsByClassName('feedback-btn')[0] as HTMLButtonElement;

        button.click();
    }

    // #endregion

}
