/* libs/backoffice/src/Views/Dialogs/document-dialog.component.scss */
:host h2 {
  color: #24506e;
  font-size: 38px;
}
:host .mat-mdc-dialog-actions {
  margin-bottom: -45px;
  min-height: 0;
}
:host .mat-mdc-dialog-title {
  margin-bottom: 20px;
}
:host .mat-mdc-dialog-content {
  max-height: 100%;
  overflow: hidden;
}
:host .mat-mdc-dialog-content .download {
  text-align: left;
  padding-left: 15px;
  margin-bottom: 15px;
}
:host .mat-mdc-dialog-content .download .mat-icon {
  margin-right: 10px;
}
:host .mat-mdc-dialog-content .spinner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9;
  padding-top: 10%;
}
:host .mat-mdc-dialog-content pdf-viewer ::ng-deep .ng2-pdf-viewer-container {
  border: 1px solid #e7e9eb;
  max-height: 80%;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  max-width: 68%;
}
:host .mat-mdc-dialog-content pdf-viewer ::ng-deep .ng2-pdf-viewer-container:hover {
  overflow-y: scroll;
}
/*# sourceMappingURL=document-dialog.component.css.map */
