/* libs/backoffice/src/Views/acknowledgement-form.component.scss */
:host .mat-mdc-checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  height: 35px;
}
:host form {
  padding-right: 16px;
  overflow: hidden;
  overflow-y: scroll;
  height: 70vh !important;
}
:host form .button-blue {
  margin-bottom: 15px;
}
/*# sourceMappingURL=acknowledgement-form.component.css.map */
