/* libs/backoffice/src/Views/SettlementDocumentsView.scss */
::ng-deep .mat-mdc-tooltip.allow-cr {
  font-size: 15px;
  white-space: pre-wrap;
}
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host h2 {
  margin: 0;
}
:host .file {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
}
:host .cdk-overlay-container ::ng-deep .allow-cr {
  white-space: pre;
}
:host .table-title h2 {
  width: 77%;
}
:host .table-title span {
  margin-top: 21px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
:host .table-title span mat-icon {
  margin-left: 15px;
  font-size: 22px;
}
:host l7-upload-documents ::ng-deep .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
  max-height: 52px;
}
:host l7-upload-documents ::ng-deep l7-file-uploader p {
  font-size: 14px;
}
:host *,
:host *:focus,
:host *:hover,
:host *:active {
  outline: none;
}
:host mat-table .mat-mdc-cell:nth-child(1),
:host mat-table .mat-mdc-header-cell:nth-child(1) {
  flex: 0 0 41%;
}
:host mat-table .mat-mdc-cell:nth-child(2),
:host mat-table .mat-mdc-header-cell:nth-child(2) {
  flex: 0 0 10%;
}
:host mat-table .mat-mdc-cell:nth-child(3),
:host mat-table .mat-mdc-header-cell:nth-child(3) {
  flex: 0 0 15%;
}
:host mat-table .mat-mdc-cell:nth-child(4),
:host mat-table .mat-mdc-header-cell:nth-child(4) {
  flex: 0 0 20%;
}
:host mat-table .mat-mdc-cell:nth-child(5),
:host mat-table .mat-mdc-header-cell:nth-child(5) {
  flex: 1;
  justify-content: center;
}
:host mat-table mat-row mat-cell {
  padding-right: 16px;
}
:host mat-table mat-row mat-cell .mat-mdc-button {
  color: #1b7e9a;
  padding: 0;
}
/*# sourceMappingURL=SettlementDocumentsView.css.map */
