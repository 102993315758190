<ng-container>

</ng-container>
<form [formGroup]="acknowledgementForm"
      (submit)="save()"
      fxFill
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="16px">
    <div fxLayout="column">
        <l7-date-picker fxFlex
                        [label]="'backoffice.acknowledgementFrom.acknowledgementDateLabel'"
                        [controlName]="'acknowledgement_date'"
                        [uniqueId]="'AcknowledgementDate'"
                        [minDate]="minDate"
                        [formFieldAppearance]="'fill'"
                        [parentFormGroup]="acknowledgementForm">
        </l7-date-picker>
    </div>
    <div fxLayout="column">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label>{{ 'settlement.dialogs.kuev.sr' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="serial_number"
                   [attr.test-id]="'SerialNumber'">
            <mat-error *ngIf="acknowledgementForm.controls.serial_number.getError('required')">
                {{'error.field_required' | translate}}
            </mat-error>
            <mat-error *ngIf="acknowledgementForm.controls.serial_number.invalid && acknowledgementForm.controls.serial_number.hasError('pattern')">
                I, O, Q nicht zulässig
            </mat-error>
            <mat-error *ngIf="(acknowledgementForm.controls.serial_number.getError('minlength') ||
                              acknowledgementForm.controls.serial_number.getError('maxlength')) && !acknowledgementForm.controls.serial_number.hasError('pattern')">
                17 Zeichen nötig
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="column"
         fxFlexAlign="flex-start">
        <mat-checkbox (change)="changedChassisNumber($event)"
                      formControlName="chassis_number"
                      [attr.test-id]="'ChassisNumber'">
            <span class="radio-label">{{ 'settlement.dialogs.kuev.vinNumber' | translate }}</span>
        </mat-checkbox>
    </div>
    <div fxLayout="column">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label>{{ 'settlement.dialogs.kuev.objectDescription' | translate }} </mat-label>
            <input matInput
                   type="text"
                   formControlName="object_name">
        </mat-form-field>
    </div>
    <div fxLayout="column">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label  *ngIf="slbData?.code === ISaleAndLeaseBackCodeDto.Unechtlnmk || slbData?.code === ISaleAndLeaseBackCodeDto.Unechtlimk; else noneSmb">{{ 'settlement.dialogs.kuev.lesseeNameMkn' | translate }}</mat-label>
            <ng-template #noneSmb>
                <mat-label>{{ 'settlement.dialogs.kuev.lesseeName' | translate }}</mat-label>
            </ng-template>
            <input matInput
                   type="text"
                   formControlName="lessee_name">
        </mat-form-field>
    </div>
    <div fxLayout="column" *ngIf="documentType!=='KUEV'">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label>{{ 'global.vendor' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="vendor_name">
        </mat-form-field>
    </div>
    <div fxLayout="column">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label>{{ 'settlement.dialogs.kuev.objectLocation' | translate}}</mat-label>
            <input matInput
                   type="text"
                   formControlName="lessee_address">
        </mat-form-field>
    </div>
    <div fxLayout="column"
         fxLayout.gt-sm="row">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label>{{ 'settlement.dialogs.kuev.condition' | translate}}</mat-label>
            <input matInput
                   type="text"
                   formControlName="condition">
        </mat-form-field>
    </div>
    <div fxLayout="column"
         fxLayout.gt-sm="row">
        <mat-form-field fxFlex
                        appearance="fill">
            <mat-label>{{ 'settlement.dialogs.kuev.yearOfConstruction' | translate}}</mat-label>
            <mat-select formControlName="year_of_construction"
                        [attr.test-id]="'SelectYear'">
                <mat-option *ngFor="let year of years"
                            [value]="year">{{ year }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-container *ngIf="documentType === 'KUEV'">
        <div fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>{{'settlement.dialogs.kuev.invoiceNumber' | translate}}</mat-label>
                <input matInput
                       type="text"
                       formControlName="invoice_number">
            </mat-form-field>
        </div>
        <div fxLayout="column"
             *ngIf="slbData?.code === ISaleAndLeaseBackCodeDto.UnechtlnV2 || slbData?.code === ISaleAndLeaseBackCodeDto.Unechtlnmk"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>{{'configuration.settings.iban' | translate}}</mat-label>
                <input matInput
                       type="text"
                       mask="AAAA AAAA AAAA AAAA AAAA AA"
                       formControlName="iban">
            </mat-form-field>
        </div>
        <div fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>{{'slb.tax_number_lessee' | translate}}</mat-label>
                <input matInput
                       type="text"
                       formControlName="tax_number_lessee">
            </mat-form-field>
        </div>
        <div fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>{{'settlement.dialogs.kuev.price' | translate}}</mat-label>
                <input matInput
                       type="text"
                       formControlName="purchase_price">
            </mat-form-field>
        </div>
    </ng-container>

    <div fxLayout="column"
         fxFlexAlign="flex-start">
        <mat-checkbox formControlName="confirmed"
                      [attr.test-id]="'AckConfirmed'">
            <span class="radio-label">{{confirmLabel | async}}</span>
        </mat-checkbox>
    </div>

    <div fxLayout="row"
         fxLayoutAlign="end"
         fxLayoutGap="16px">
        <button mat-flat-button
                [color]="'accent'"
                type="submit"
                *ngIf="!isReadonlyUser"
                mat-flat-button
                [color]="'accent'"
                [attr.test-id]="'SaveAckButton'"
                [disabled]="(contractStatus !== 'BILLABLE' && contractStatus !== 'TO_BE_DISCUSSED') ||
                                navStatus >= 50 || !acknowledgementForm.valid">
            {{ 'global.save' | translate }}
        </button>
    </div>
</form>
