import { AuthService, UserService } from '@abcfinlab/auth';
import { FeatureManager } from '@abcfinlab/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { MessageBoxButton, MessageBoxService } from '../../../../../../../libs/ui/src/Index';
import { BrowserDetectionService } from '../../../shared/services/browser-detection/browser-detection.service';

@UntilDestroy()
@Component({
    selector: 'l7-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    standalone: false,
})
export class LandingPageComponent implements OnInit {

    public constructor(
        private readonly _router: Router,
        private readonly _authService: AuthService,
        private readonly _userService: UserService,
        private readonly _detector: BrowserDetectionService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _featureManager: FeatureManager,
    ) {
        this._detector.init();
    }

    public ngOnInit(): void {
        this._authService.getSession().pipe(untilDestroyed(this)).subscribe(() => {
            this._userService.getUserInfo()
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    // TODO: remove navigation in featureManager
                    const path = this._featureManager.active('navigation', true)
                        ? 'presentation'
                        : 'presentation'; // this was before 'private';
                    void this._router.navigate([path], { replaceUrl: true }).then();
                });
        });

        this._detector.supported$.pipe(
            map((isSuppported) => {
                if (!isSuppported) {
                    this._messageBoxService.show('Ungültiger Browser', `Diese Anwendung kann mit dem ${this._detector.browserInformation?.browser?.name} nicht genutzt werden.`, MessageBoxButton.None);
                }
            }),
            untilDestroyed(this),
        ).subscribe();
    }

}
