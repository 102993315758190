/* libs/contractManagement/src/Views/Dialogs/purchase-entry-object-value/purchase-entry-object-value.component.scss */
:host h2 {
  color: #5e6f79;
  text-align: left;
  font-size: 18px;
}
:host p {
  margin-top: 10px;
}
:host .mat-mdc-dialog-content {
  text-align: justify;
}
:host .mat-mdc-dialog-actions {
  justify-content: flex-end;
}
/*# sourceMappingURL=purchase-entry-object-value.component.css.map */
