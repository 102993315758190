/* libs/contractManagement/src/Views/ContractOverviewDetailsView.scss */
:host {
  margin-top: 32px;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=ContractOverviewDetailsView.css.map */
