// #region Imports

import { ContractsDocumentService, IContractDocumentDto } from '@abcfinlab/api/contract';
import { IWorkflowStepDto } from '@abcfinlab/api/global';
import { ToastService } from '@abcfinlab/ui';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RetailerContractDetailsViewPresenter } from 'libs/contractManagement/src/Views/retailer-contract-managment/retailer-contract-details-view/RetailerContractDetailsViewPresenter';
import { TranslationFacade } from '../../../../../core/src/Services/TranslationFacade';

// #endregion

/**
 * The `RetailerContractDetailsView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-contract-details-view',
    templateUrl: './RetailerContractDetailsView.html',
    styleUrls: ['./RetailerContractDetailsView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerContractDetailsViewPresenter,
    ],
    standalone: false,
})
export class RetailerContractDetailsView implements OnInit, OnDestroy {

    // #region Fields
    private readonly _presenter: RetailerContractDetailsViewPresenter;

    private readonly _contractsDocumentsService: ContractsDocumentService;

    private readonly _toastService: ToastService;

    private readonly _translationFacade: TranslationFacade;

    private readonly _columns: Array<string>;

    @ViewChild('fileInput')
    private readonly fileInput: ElementRef;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerContractDetailsView` class.
     *
     * @public
     */
    public constructor(presenter: RetailerContractDetailsViewPresenter, contractsDocumentsService: ContractsDocumentService,
        toastService: ToastService, translationFacade: TranslationFacade) {
        this._presenter = presenter;
        this._contractsDocumentsService = contractsDocumentsService;
        this._toastService = toastService;
        this._translationFacade = translationFacade;
        this._columns = ['name', 'creationDate', 'createdBy', 'type', 'actions'];
    }

    // #endregion

    // #region Properties

    /**
     * Returns the presenter of the `RetailerContractDetailsView`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerContractDetailsViewPresenter {
        return this._presenter;
    }

    /**
     * Returns the property `columns`
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    public navigateTo(): void {
    }

    public triggerInvoiceUpload(): void {
        this.fileInput.nativeElement.value = '';
        this.fileInput.nativeElement.click();
    }

    public onTaskSelected(task: IWorkflowStepDto): void {
        if (!task.startable) {
            return;
        }
        if (task.stepType === 'ACKNOWLEDGEMENT') {
            this._presenter.openTakeoverDocumentationUploadDialog(task);
        }
        if (task.stepType === 'UPLOAD_INVOICE') {
            this.triggerInvoiceUpload();
        }
        if (task.stepType === 'REQUEST_PAYMENT') {
            this._presenter.triggerRequestPayment(task);
        }
    }

    public fileDownload(row: IContractDocumentDto): void {
        this._contractsDocumentsService.downloadDocumentPdf({ documentId: row.id }).pipe(untilDestroyed(this)).subscribe((document) => {
            const documentUrl = URL.createObjectURL(document);
            window.open(documentUrl, '_blank');
        }, (error) => {
            this._toastService.show(this._translationFacade.instant('error.generic_error'), 'danger');
        });
    }

    // #endregion

}
