import { Injectable } from '@angular/core';
import Bowser from 'bowser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BrowserDetectionService {

    public browserInformation: any = null;
    public blackList: Array<string> = ['Explorer'];

    public supported$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor() {
        this.browserInformation = Bowser.parse(window.navigator.userAgent);
    }

    /**
   * Iterate over the blacklisted array to check if the browser is supported
   */
    public init() {
        this.blackList.forEach((value, index, array) => {
            if (this.browserInformation.browser.name.includes(value)) {
                this.supported$.next(false);
            }
        });
    }

}
