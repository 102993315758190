/* apps/l7/src/app/app.component.scss */
:host {
  display: flex;
}
:host .outlet-container {
  height: 100%;
}
:host .header-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
}
:host mat-progress-bar {
  position: absolute;
  top: 0;
  z-index: 1001;
}
::ng-deep .whats-new-portal {
  display: block;
  background-color: #fff;
  height: 90vh;
  width: 100vw;
}
/*# sourceMappingURL=app.component.css.map */
