/* libs/contractManagement/src/Views/ContractOverviewDocumentsView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host h2 {
  margin: 0;
}
:host *,
:host *:focus,
:host *:hover,
:host *:active {
  outline: none;
}
:host mat-table .mat-mdc-cell:nth-child(1),
:host mat-table .mat-mdc-header-cell:nth-child(1) {
  flex: 0 0 41%;
}
:host mat-table .mat-mdc-cell:nth-child(2),
:host mat-table .mat-mdc-header-cell:nth-child(2) {
  flex: 0 0 10%;
}
:host mat-table .mat-mdc-cell:nth-child(3),
:host mat-table .mat-mdc-header-cell:nth-child(3) {
  flex: 0 0 15%;
}
:host mat-table .mat-mdc-cell:nth-child(4),
:host mat-table .mat-mdc-header-cell:nth-child(4) {
  flex: 0 0 20%;
}
:host mat-table .mat-mdc-cell:nth-child(5),
:host mat-table .mat-mdc-header-cell:nth-child(5),
:host mat-table .mat-mdc-cell:nth-child(6),
:host mat-table .mat-mdc-header-cell:nth-child(6) {
  flex: 1;
  justify-content: center;
}
:host mat-table mat-row mat-cell {
  padding-right: 16px;
}
/*# sourceMappingURL=ContractOverviewDocumentsView.css.map */
