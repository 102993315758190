<div fxFlex
     fxLayout="column">
    <div mat-dialog-title
         fxLayout="column"
         fxLayoutAlign="start start"
         class="dialog-title">
        <h2>{{ 'Fehler Dokumentenübertragung' | translate }}</h2>

        <l7-text-block class="dialog-title-sub"
                       [wrap]="true"
                       [disabled]="true"
                       [text]="'Vertrag in Status NAV manuell. Es gab einen Fehler bei der Dokumentenübertragung. Bitte in Navision prüfen. ' + ((this.presenter.data | async).submitDate | date:'short') + ' | ' + (this.presenter.data | async).contractNo"></l7-text-block>

    </div>
    <mat-dialog-content fxFlex
                        fxLayout="column"
                        fxLayoutGap="8px">
        <div *ngFor="let error of (this.presenter.data | async).errors">
            <div fxFlex
                 fxLayout="row" *ngIf="!error.value.success">
                <div fxFlex
                     fxLayout="row"
                     fxLayoutAlign="start start"
                     fxLayoutGap="8px">
                    <mat-icon [color]="error.value.status === 'WARNING' ? 'warning' : 'danger'"
                              [inline]="true"
                              [fontSet]="'l7'"
                              [fontIcon]="error.value.status === 'WARNING' ? 'Alert_Circle_Filled' : 'Cross_Circle_Filled'"></mat-icon>
                    <div fxLayout="column"
                         fxFlex>
                        <l7-text-block [text]="error.key | translate"></l7-text-block>
                        <l7-text-block *ngIf="error.value.message"
                                       [wrap]="true"
                                       [breakLine]="true"
                                       [maxLines]="2"
                                       [disabled]="true"
                                       [text]="error.value.message"></l7-text-block>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-flat-button
                color="accent"
                mat-dialog-close
                [attr.test-id]="'CancelButton'">{{ 'OK' | translate }}</button>
    </mat-dialog-actions>
</div>

