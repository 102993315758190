<l7-page [isEmpty]="(this.presenter.dataSourceTotal | async) === 0">
    <l7-page-header [title]="'administration.retailers.overview.title' | translate"></l7-page-header>
    <l7-page-pre-content>
        <l7-page-section fxLayout="row"
                         fxLayoutAlign="start start"
                         fxLayoutGap="16px">
            <mat-form-field fxFlex.lt-md="100"
                            fxFlex="40"
                            appearance="outline">
                <mat-label>{{ 'administration.retailers.overview.search' | translate }}</mat-label>
                <input #term="matInput"
                       matInput
                       [attr.test-id]="'TermInput'"
                       [value]="(this.presenter.condition | async).term"
                       (keyup.enter)="this.presenter.onTermChanged(term.value)">
                <button mat-icon-button
                        matSuffix
                        (click)="this.presenter.onTermChanged(term.value)">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Search'"></mat-icon>
                </button>
            </mat-form-field>
            <div fxFlex
                 class="clear-all-container">
                <button *ngIf="(this.presenter.isDefaultSearchCondition | async) === false"
                        mat-button
                        [color]="'warn'"
                        [attr.test-id]="'ResetConditionButton'"
                        (click)="this.presenter.onResetConditions()">{{ 'administration.retailers.overview.filter.reset' | translate }}</button>
            </div>
            <div class="quick-action-container">
                <button mat-flat-button
                        [color]="'primary'"
                        [attr.test-id]="'CreateRetailerButton'"
                        (click)="this.presenter.onCreateRetailer()">{{ 'administration.retailers.overview.createRetailer' | translate }}</button>
            </div>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content>
        <mat-card>
            <mat-card-content>
                <mat-table [attr.test-id]="'OverviewTable'"
                           [dataSource]="this.presenter.dataSource">
                    <ng-container *ngFor="let column of this.presenter.columns"
                                  [matColumnDef]="column">
                        <mat-header-cell *matHeaderCellDef>
                            <l7-text-block [truncate]="true"
                                           [text]="'administration.retailers.overview.table.' + column | translate"></l7-text-block>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <ng-container [ngSwitch]="column">
                                <ng-container *ngSwitchCase="'active'">
                                    <mat-chip-listbox>
                                        <mat-chip-option class="text-truncate"
                                                  [color]="row[column] ? 'primary' : 'default'">
                                            <l7-text-block [truncate]="true"
                                                           [text]="(row[column] ? 'administration.global.active' : 'administration.global.inactive') | translate"></l7-text-block>
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <l7-text-block [truncate]="true"
                                                   [text]="row | memberValue:column"></l7-text-block>
                                </ng-container>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="this.presenter.columns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: this.presenter.columns;"
                             matRipple
                             (click)="presenter.onRowSelected(row)"></mat-row>
                </mat-table>
                <mat-paginator fxLayout="row"
                               fxLayoutAlign="center center"
                               showFirstLastButtons
                               [length]="this.presenter.dataSourceTotal | async"
                               [pageSize]="this.presenter.pageSize | async"
                               [pageSizeOptions]="this.presenter.pageSizes | async"
                               (page)="this.presenter.onPageChanged($event)">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </l7-page-content>
</l7-page>
