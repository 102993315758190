/* libs/backoffice/src/Views/InvoiceContractInformationView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
:host fieldset .transparencyicon {
  font-size: 17px;
}
:host l7-text-block ::ng-deep .text {
  font-size: 14px;
  line-height: 20px;
}
:host .mat-mdc-menu-trigger {
  cursor: pointer;
  height: 14px;
}
:host .mat-mdc-menu-trigger mat-icon {
  position: relative;
  top: 2px;
}
/*# sourceMappingURL=InvoiceContractInformationView.css.map */
