/* libs/contractManagement/src/Views/contract-purchase-entry-view/contract-purchase-entry-view.component.scss */
:host .object-warning {
  color: #910813;
}
:host .object-warning .mat-icon {
  margin-right: 5px;
  font-size: 15px;
}
:host .l7-icon-design {
  position: relative;
  top: 60%;
  left: 1%;
  transform: translate(-50%, -50%);
}
:host .purchase-entry-form-container {
  padding: 15px;
}
:host .purchase-entry-form-container l7-file-uploader ::ng-deep .uploadfilecontainer {
  max-width: 50%;
}
:host .purchase-entry-form-container .object-link {
  text-align: right;
  color: #197e9a;
  margin-top: 16px;
}
:host .purchase-entry-form-container .object-link a {
  color: #197e9a;
  text-decoration: underline;
  margin-left: 5px;
}
:host .purchase-entry-form-container .object-link mat-icon {
  font-size: 24px;
}
:host .purchase-entry-form-container .mat-mdc-form-field {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  width: auto;
  height: auto;
}
:host .purchase-entry-form-container .text-purchase-entry {
  margin-bottom: 15px;
}
:host .purchase-entry-form-container .text-vendor-offer {
  margin-bottom: 15px;
}
:host .purchase-entry-form-container .text-vendor-offer mat-icon {
  font-size: 24px;
}
:host .purchase-entry-form-container .text-vendor-offer mat-icon.Question {
  color: #197e9a;
}
:host .purchase-entry-form-container input.mat-mdc-chip-input {
  width: 100%;
}
:host .purchase-entry-form-container .button {
  margin: 5px 0;
}
:host .purchase-entry-form-container .button.button-blue {
  background-color: #197e9a;
  color: #fff;
}
:host .purchase-entry-container {
  text-align: center;
}
:host .purchase-entry-container div:nth-child(2) {
  padding-top: 15px;
  min-height: 125px;
}
:host .purchase-entry-container div:nth-child(2) p {
  margin-top: 10px;
}
/*# sourceMappingURL=contract-purchase-entry-view.component.css.map */
