import { IObjectConditionDto, IVersionedSlbGetResponseDto } from '@abcfinlab/api/contract';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'l7-kuev-comparison',
    templateUrl: './kuev-comparison.component.html',
    styleUrls: ['./kuev-comparison.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class KuevComparisonComponent implements OnInit {

    public maxColumns: number = 4;
    public minColumns: number = 4;

    @Input() activeVersion: IVersionedSlbGetResponseDto;
    @Input() inactiveVersion: IVersionedSlbGetResponseDto;

    @Input() objectCondition: IObjectConditionDto;

    @Input() isMKN: boolean;

    ngOnInit() {
        this.maxColumns = this.activeVersion && this.inactiveVersion ? 2 : 4;
        this.minColumns = this.activeVersion && this.inactiveVersion ? 2 : 4;
    }

}
