/* libs/backoffice/src/Views/Dialogs/ManualProcessingErrorDialog.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
  text-align: initial;
}
:host .dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  margin-bottom: 8px;
}
/*# sourceMappingURL=ManualProcessingErrorDialog.css.map */
