// #region Imports

import { IbanCheckService, IBankAccountDto } from '@abcfinlab/api/global';
import { ICreateWithUserRequestDto, IRetailerAdminConfigDto, IRetailerUserResponseDto, RetailerAdminService, RetailerCustomizationService } from '@abcfinlab/api/retailer';
import { ControlsOf, Validators as CoreValidators, Globals, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { base64ToBlob } from '../../../../apps/l7/src/app/helper/presentation.helper';
import { BankAccountDTO } from '../../../../apps/l7/src/app/models/BankAccountDTO.interface';
import { DialogService } from '../../../../apps/l7/src/app/private/services/dialog/dialog.service';
import { LogoUploadComponent } from '../../../presentation/src/Components/logo-upload/logo-upload.component';

interface ISettingsFormDto {
    provision: FormControl<number>;
    adjustableUserProvision: FormControl<boolean>;
    showObjectValueOnOffer: FormControl<boolean>;
    accountOwner: FormControl<string>;
    iban: FormControl<string>;
}

// #endregion

/**
 * The presenter of the {@link ConfigurationOverviewView} view.
 *
 * @internal
 */
@UntilDestroy()

@Injectable()
export class RetailerConfigurationOverviewViewPresenter {

    // #region Fields

    private readonly _retailerCustomizationService: RetailerCustomizationService;
    private readonly _retailerAdminService: RetailerAdminService;
    private readonly _busyBox: BusyBoxService;
    private readonly _settingsForm: FormGroup<ControlsOf<ISettingsFormDto>>;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _dataSource: MatTableDataSource<IRetailerUserResponseDto>;
    private readonly _dataSourceTotalSubject: BehaviorSubject<number>;
    private readonly _pageSizeSubject: BehaviorSubject<number>;
    private readonly _pageSizesSubject: BehaviorSubject<Array<number>>;
    private readonly _usersTableColumns: Array<string>;
    private readonly _logoURL$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _useCustomLogo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isSettingsChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _retailerInfo$: BehaviorSubject<ICreateWithUserRequestDto> = new BehaviorSubject<ICreateWithUserRequestDto>(null);
    private readonly _retailerSettings$: BehaviorSubject<IRetailerAdminConfigDto> = new BehaviorSubject<IRetailerAdminConfigDto>(null);
    private readonly _bankAccount$: BehaviorSubject<BankAccountDTO> = new BehaviorSubject<BankAccountDTO>(null);
    private _officeServiceEmail: string;
    private _companyName: string;
    private _isAdjustableUserProvision: boolean;
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ConfigurationOverviewViewPresenter` class.
     *
     * @public
     */
    public constructor(
        retailerCustomizationService: RetailerCustomizationService, retailerAdminService: RetailerAdminService,
        busyBox: BusyBoxService, activatedRoute: ActivatedRoute,
        private readonly _dialog: DialogService, private readonly translationFacade: TranslationFacade, private readonly toastService: ToastService,
        private readonly _ibanCheckService: IbanCheckService, messageBoxService: MessageBoxService) {
        this._retailerCustomizationService = retailerCustomizationService;
        this._retailerAdminService = retailerAdminService;
        this._busyBox = busyBox;
        this._messageBoxService = messageBoxService;
        this._activatedRoute = activatedRoute;
        this._settingsForm = new FormGroup<ControlsOf<ISettingsFormDto>>({
            provision: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(5)]),
            adjustableUserProvision: new FormControl(false),
            showObjectValueOnOffer: new FormControl(false),
            accountOwner: new FormControl(''),
            iban: new FormControl('', null, CoreValidators.validateIban(this._ibanCheckService)),
        });
        this._dataSourceTotalSubject = new BehaviorSubject(0);
        this._pageSizeSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZE);
        this._pageSizesSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZES);
        this._dataSource = new MatTableDataSource();
        this._usersTableColumns = ['givenName', 'familyName', 'email', 'phoneNumber', 'groups', 'active'];
    }
    // #endregion

    // #region Properties
    /**
     * Returns the `logoImage` property.
     *
     * @public
     * @readonly
     */

    public get columns(): Array<string> {
        return this._usersTableColumns;
    }

    public onPageChanged(page: PageEvent): void {
        this._pageSizeSubject.next(page.pageSize);
        this.initializeRetailerUsers(page.pageSize, page.pageIndex);
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<IRetailerUserResponseDto> {
        return this._dataSource;
    }

    /**
     * Returns the `dataSourceTotal` property.
     *
     * @public
     * @readonly
     */
    public get dataSourceTotal(): Observable<number> {
        return this._dataSourceTotalSubject.asObservable();
    }

    /**
     * Returns the `pageSize` property.
     *
     * @public
     * @readonly
     */
    public get pageSize(): Observable<number> {
        return this._pageSizeSubject.asObservable();
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    public get officeServiceEmail(): string {
        return this._officeServiceEmail;
    }

    public get isAdjustableUserProvision(): boolean {
        return this._isAdjustableUserProvision;
    }

    public get retailerCompany(): string {
        return this._companyName;
    }

    public get settingsForm(): FormGroup<ControlsOf<ISettingsFormDto>> {
        return this._settingsForm;
    }

    public get logoImageURL(): Observable<string> {
        return this._logoURL$.asObservable();
    }

    public get retailerInfo(): Observable<ICreateWithUserRequestDto> {
        return this._retailerInfo$.asObservable();
    }

    public get isCustomLogoUsed(): Observable<boolean> {
        return this._useCustomLogo$.asObservable();
    }

    public get isSettingsChanged(): Observable<boolean> {
        return this._isSettingsChanged$.asObservable();
    }

    public get bankAccountInfo(): Observable<BankAccountDTO> {
        return this._bankAccount$.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._activatedRoute.data.subscribe((x) => {
            this._retailerInfo$.next(x.retailerAdminInfo);
            this._dataSource.data = x.retailerAdminSearchUsers.content;
            this._dataSourceTotalSubject.next(x.retailerAdminSearchUsers.totalElements);
            this._officeServiceEmail = x.retailerAdminInfo.contact.officeService.email;
            this._isAdjustableUserProvision = x.retailerAdminInfo.retailerConfig.adjustableProvision;
            this._companyName = x.retailerAdminInfo.retailer.name;
            this.setSettingsFormInitial(x.retailerAdminConfig);
        });
        this.updateLogo();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    public updateLogo(): void {
        this._busyBox.show(undefined, '', this._retailerCustomizationService.getLogo())
            .subscribe((logoResponse) => {
                const blob = base64ToBlob(logoResponse.base64EncodedLogo);
                const imageURL = URL.createObjectURL(blob);
                this._useCustomLogo$.next(logoResponse.customized);
                this._logoURL$.next(imageURL);
            });
    }

    public showContractDraft(): void {
        this._retailerCustomizationService.getContractDraft().pipe().subscribe((draftPdf) => {
            const draftPdfUrl = URL.createObjectURL(draftPdf);
            window.open(draftPdfUrl, '_blank');
        }, (error) => {
            this.toastService.show(this.translationFacade.instant('error.generic_error'), 'danger');
        });
    }

    public openLogoUploadDialog(uploadedFile: any, defaultLogoConfig: typeof Globals.defaultLogoConfig): void {
        const dialogRef = this._dialog.openDialog(LogoUploadComponent, {}, { imagetoCrop: uploadedFile, logoConfig: defaultLogoConfig });
        dialogRef.afterClosed().pipe(
            untilDestroyed(this),
        ).subscribe((result) => {
            if (result) {
                this._retailerCustomizationService.postLogo({ body: { logo: result.croppedImageFile } })
                    .pipe(untilDestroyed(this))
                    .subscribe((res) => {
                        this.updateLogo();
                        this.toastService.show(this.translationFacade.instant('global.save_successful'), 'success');
                    }, (_error) => {
                        this._useCustomLogo$.next(false);
                        this.toastService.show(this.translationFacade.instant('error.generic_error'), 'danger');
                    });
            }
        });
    }

    public resetLogo(): void {
        this._busyBox.show(undefined, '', this._retailerCustomizationService.deleteLogo())
            .subscribe((x) => {
                this.updateLogo();
            });
    }

    public transformToUpperCase(evt): void {
        const value: string = evt.target.value.toUpperCase();
        this._settingsForm.get('iban').patchValue(value);
    }

    public getBankInformation(iban: string): void {
        this._ibanCheckService.getBankAccountbyIban({ iban }).subscribe((response) => {
            this._bankAccount$.next(response);
        });
    }

    public handleBankAccountInfo(bankAccount: IBankAccountDto): void {
        this._bankAccount$.next(bankAccount);
    }

    public checkSettingFormChanges(formChanges: any): void {
        const isChanged = (formChanges.adjustableUserProvision !== this._retailerSettings$.value.adjustableUserProvision)
            || (formChanges.accountOwner !== this._retailerSettings$.value.retailerBankAccount?.bankAccountName)
            || (formChanges.iban !== this._retailerSettings$.value.retailerBankAccount?.iban)
            || (formChanges.provision?.toString() !== this._retailerSettings$.value.provision.toString()) || (formChanges.showObjectValueOnOffer !== this._retailerSettings$.value.showObjectValueOnOffer);

        this._isSettingsChanged$.next(isChanged);
    }

    public resetChangedSettings(): void {
        this._messageBoxService.show(this.translationFacade.instant('configuration.settings.cancel_changes_modal_title'), this.translationFacade.instant('configuration.settings.cancel_changes_modal_body'), MessageBoxButton.OKCancel, {
            labels: {
                ok: this.translationFacade.instant('global.yes'),
                cancel: this.translationFacade.instant('global.no'),
            },
        }).subscribe((x) => {
            if (x === MessageBoxResult.OK) {
                this.setSettingsFormInitial(this._retailerSettings$.value);
            }
        });
    }

    public updateRetailerSettings(): void {
        this._retailerSettings$.next({
            provision: this.settingsForm.getRawValue().provision,
            adjustableUserProvision: this.settingsForm.getRawValue().adjustableUserProvision,
            showObjectValueOnOffer: this.settingsForm.getRawValue().showObjectValueOnOffer,
            retailerBankAccount: {
                bankAccountName: this.settingsForm.getRawValue().accountOwner,
                iban: this.settingsForm.getRawValue().iban,
            },
        });
        this._busyBox.show(undefined, '', this._retailerAdminService.updateRetailer({ body: this._retailerSettings$.value }))
            .subscribe((x) => {
                this.toastService.show(this.translationFacade.instant('global.save_successful'), 'success');
                this._isSettingsChanged$.next(false);
            }, error => this.toastService.show(this.translationFacade.instant('global.generic_error'), 'danger'));
    }

    public setSettingsFormInitial(initialValues: IRetailerAdminConfigDto): void {
        this._retailerSettings$.next(initialValues);
        this.settingsForm.setValue({
            provision: initialValues.provision,
            accountOwner: initialValues.retailerBankAccount ? initialValues.retailerBankAccount?.bankAccountName : '',
            iban: initialValues.retailerBankAccount ? initialValues.retailerBankAccount.iban : '',
            adjustableUserProvision: initialValues.adjustableUserProvision,
            showObjectValueOnOffer: initialValues.showObjectValueOnOffer,
        });
        if (initialValues.retailerBankAccount) {
            this.getBankInformation(initialValues.retailerBankAccount.iban);
        }
    }

    private initializeRetailerUsers(size: number, index: number): void {
        once(this._busyBox.show(null, this.translationFacade.instant('global.busy'), this._retailerAdminService.searchUser({
            page: index,
            pageSize: size,
            searchString: '',
        }), { id: 'initializeRetailerUsersInConf' }), (x) => {
            this._dataSource.data = x.content;
            this._dataSourceTotalSubject.next(x.totalElements);
        });
    }
    // #endregion

}
