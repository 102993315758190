<form #billingInformationForm="ngForm"
      ngForm
      (submit)="this.onSave(billingInformationForm)">
    <h2>{{ 'contract_management.billing_information.pre_rent' | translate }}</h2>
    <mat-radio-group fxLayout="column"
                     [name]="'preRent'"
                     [disabled]="(this.overview | async)?.ready?.sent_to_accounting"
                     [(ngModel)]="this.accounting.pre_rent">
        <h4 class="radio-label"
            [innerHTML]="'contract_management.billing_information.this_month.title' | translate"></h4>
        <mat-radio-button [color]="'primary'"
                          [value]="'FIRST_DAY_OF_THIS_MONTH'"
                          [attr.test-id]="'FirstDayOfThisMonthRadioButton'">
            <span class="radio-label">{{ 'contract_management.billing_information.this_month.first_day' | translate }}</span>
        </mat-radio-button>
        <h4 class="radio-label"
            [innerHTML]="'contract_management.billing_information.next_month.title' | translate"></h4>
        <mat-radio-button [color]="'primary'"
                          [value]="'NEXT_MONTH_CALCULATED'"
                          [attr.test-id]="'NextMonthCalculated'">
            <span class="radio-label">{{ 'contract_management.billing_information.next_month.calculated' | translate }}</span>
        </mat-radio-button>
        <mat-radio-button #manualRadioButton
                          [color]="'primary'"
                          [value]="'NEXT_MONTH_MANUAL_ENTERED'"
                          [attr.test-id]="'NextMonthManualEnteredRadioButton'"
                          (selectionChange)="manualInput.focus()">
            <div fxLayout="row"
                 fxLayoutAlign="start center">
                <span class="radio-label">{{ 'contract_management.billing_information.next_month.manual_entered' | translate }}</span>
                <mat-form-field style="width: 300px;" class="appearanceFill">
                    <mat-label>{{ 'contract_management.billing_information.next_month.manual_entered_value' | translate }}</mat-label>
                    <input #manualInput
                           #manualInputModel="ngModel"
                           matInput
                           currencyMask
                           cdkFocusInitial
                           name="preRentValue"
                           l7SelectOnDblClick
                           [disabled]="!manualRadioButton.checked"
                           [required]="manualRadioButton.checked"
                           [attr.test-id]="'NextMonthManualEnteredInput'"
                           [l7Min]="1"
                           [l7Max]="(calculation | async)?.instalment"
                           [(ngModel)]="this.accounting.pre_rent_value">
                    <mat-error *ngIf="manualInputModel.invalid && manualInputModel.hasError('max') && manualInputModel.getError('max') as error">
                        Die maximale Vormiete beträgt {{ (calculation | async)?.instalment }} €.
                    </mat-error>
                    <mat-error *ngIf="manualInputModel.invalid && manualInputModel.hasError('min') && manualInputModel.getError('min') as error">
                        Min. 1 eintragen oder “Keine Vormiete” auswählen.
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-radio-button>
        <mat-radio-button [color]="'primary'"
                          [value]="'NEXT_MONTH_WITHOUT'"
                          [attr.test-id]="'NextMonthWithout'">
            <span class="radio-label">{{ 'contract_management.billing_information.next_month.without' | translate }}</span>
        </mat-radio-button>
    </mat-radio-group>
    <h2>{{ 'contract_management.billing_information.kfz_cert.title' | translate }}</h2>
    <l7-spacer [thickness]="['top']"
               [density]="'comfortable'"></l7-spacer>
    <mat-radio-group fxLayout="column"
                     [name]="'cocPresence'"
                     [disabled]="(this.overview | async)?.ready?.sent_to_accounting"
                     [(ngModel)]="this.accounting.coc_presence">
        <mat-radio-button [color]="'primary'"
                          [value]="'NOT_PRESENT'"
                          [attr.test-id]="'COCNotPresentRadioButton'">
            <span class="radio-label">{{ 'contract_management.billing_information.kfz_cert.coc_not_present_label' | translate }}</span>
        </mat-radio-button>

        <mat-radio-button [color]="'primary'"
                          [value]="'ORIGINAL_PRESENT_SUBMITTER'"
                          [attr.test-id]="'COCOriginalPresentSubmitterRadioButton'">
            <span class="radio-label">{{ 'contract_management.billing_information.kfz_cert.coc_original_submitter_label' | translate }}</span>
        </mat-radio-button>

        <mat-radio-button [color]="'primary'"
                          [value]="'ORIGINAL_PRESENT_OFFICE'"
                          [attr.test-id]="'COCOriginalPresentOfficeRadioButton'">
            <span class="radio-label">{{ 'contract_management.billing_information.kfz_cert.coc_original_office_label' | translate }}</span>
        </mat-radio-button>
    </mat-radio-group>
    <div fxLayout="row"
         fxLayoutAlign="end">
        <button mat-flat-button
                type="submit"
                [color]="'accent'"
                [attr.test-id]="'AccountingSaveButton'"
                [disabled]="(this.hasChanges | async)  === false || (this.overview | async)?.ready?.sent_to_accounting">{{'global.save_changes' | translate }}</button>
    </div>
</form>
