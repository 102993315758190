import { AuthService } from '@abcfinlab/auth';
import { FeatureManager } from '@abcfinlab/core';
import { ThemingService } from '@abcfinlab/ui';
import { AfterContentChecked, Component, HostBinding, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { LoadingService } from './shared/services/loading/loading.service';
import { RoutingstateService } from './shared/services/routingstate/routingstate.service';

@Component({
    selector: 'l7-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false,
})
export class AppComponent implements OnInit, AfterContentChecked {

    public title = environment.appName;
    public isLoading: boolean = false;
    public loginState$: Observable<boolean>;
    @HostBinding('class') public theme: string;

    constructor(
        private readonly _loadingService: LoadingService,
        private readonly _routingStateService: RoutingstateService,
        private readonly _authService: AuthService,
        private readonly _titleService: Title,
        private readonly _themingService: ThemingService,
        private readonly _featureManager: FeatureManager,
    ) {
        this._titleService.setTitle(environment.appName);
        this._routingStateService.loadRouting();
        this.loginState$ = this._authService.hasSession;
        this.theme = ThemingService.DEFAULT_THEME;
    }

    ngOnInit(): void {
        // subscribe to loading obs
        this._loadingService.getLoadingStatus().subscribe(loadingStatus => this.isLoading = loadingStatus);
        this._themingService.theme.subscribe((x) => {
            if (this._featureManager.active('darkMode', true) && x === 'dark') {
                this.theme = x;
            }
        });
    }

    ngAfterContentChecked() {
        document.querySelectorAll('mat-select').forEach(b => b.removeAttribute('role'));
    }

}
