/* apps/l7/src/app/public/pages/landing-page/landing-page.component.scss */
:host .page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
:host .page-container .headline {
  text-align: center;
}
/*# sourceMappingURL=landing-page.component.css.map */
