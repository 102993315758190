// #region Imöorts

import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupLoginsDto, IUserGroupRetailerDto } from '@abcfinlab/auth';
import { RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH } from '../Routing/RoutePaths';
import { CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH, RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH } from './ContractManagementRoutingModule';

// #endregion

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class ContractManagementNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractManagementNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService, roleManager: RoleManager, translationFacade: TranslationFacade) {
        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if (user.groups.includes(IUserGroupLoginsDto.Sales) || user.groups.includes(IUserGroupLoginsDto.SalesAssistance)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Unterzeichnete Verträge'),
                        icon: 'Folder_open',
                        order: 50,
                        route: CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH,
                    });
                }
                if (user.groups.includes(IUserGroupRetailerDto.RetailerContractManagement) || user.groups.includes(IUserGroupRetailerDto.RetailerSales)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Unterzeichnete Verträge'),
                        icon: 'Folder_open',
                        order: 50,
                        route: RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH,
                        relatedTo: [
                            { link: `/presentation/${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}` },
                        ],
                    });
                }
            });
    }

    // #endregion

}
