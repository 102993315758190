<ng-container *ngIf="this.data | async as _data">
    <div fxFlex
         fxLayout="column"
         fxLayoutGap="32px">
        <div fxFlex
             fxLayout="row"
             fxLayoutGap="32px">
            <l7-quote-summary-card fxFlex
                                   [leasingQuote]="_data?.quote"
                                   [quoteName]="_data.details.object.name"
                                   [hasSlbCode]="_data.row.slb"
                                   [hasTransparencyRegister]="(hasTransparencyRegister | async)"
                                   [calculation]="_data.calculation"
                                   [refinancingInterest]="_data.refinancingInterest"
                                   [creditCheck]="_data.creditCheck"
                                   [hasHeader]="true">
            </l7-quote-summary-card>
            <div fxFlex
                 fxLayout="column"
                 fxLayoutGap="32px">
                <div fxFlex
                     fxLayout="row"
                     fxLayoutGap="32px">
                    <l7-address-card fxFlex
                                     [title]="'Angaben zum Objektstandort'"
                                     [contact]="_data.details.lessee">
                    </l7-address-card>
                    <l7-address-card fxFlex
                                     [title]="'Angaben zum Lieferanten'"
                                     [contact]="_data.details.object.vendor">
                    </l7-address-card>
                </div>
                <mat-card>
                    <mat-card-header>
                        <h3 matCardTitle>{{ 'Bankverbindung' | translate }}</h3>
                    </mat-card-header>
                    <mat-card-content fxLayout="column">
                        <fieldset fxFlex>
                            <legend>{{ 'Kontoinhaber' | translate }}</legend>
                            <l7-text-block [truncate]="true"
                                           [text]="_data.details.lessee?.name"></l7-text-block>
                        </fieldset>
                        <fieldset fxFlex>
                            <legend>{{ 'IBAN' | translate }}</legend>
                            <l7-text-block [truncate]="true"
                                           [text]="_data.details.bank_account.iban"></l7-text-block>
                        </fieldset>
                        <div fxLayout="row">
                            <fieldset fxFlex>
                                <legend>{{ 'Name der Bank' | translate }}</legend>
                                <l7-text-block [truncate]="true"
                                               [text]="_data.details.bank_account.bank_name"></l7-text-block>
                            </fieldset>
                            <fieldset fxFlex>
                                <legend>{{ 'BIC' | translate }}</legend>
                                <l7-text-block [truncate]="true"
                                               [text]="_data.details.bank_account.bic"></l7-text-block>
                            </fieldset>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <l7-object-value-changed *ngIf="hasPurchasePriceChanged | async"
                                 [contractType]="_data.quote.inhouseQuoteCalculation.contractType"
                                 [calculationValues]="calculationValues | async"
                                 [insuranceValues]="insuranceValues | async"
                                 [slbCalculation]="(slbCalculation | async)"
                                 [isPurchasePriceChangedInactive]="(isPurchasePriceChangedInactive | async)">
        </l7-object-value-changed>

        <mat-card *ngIf="_data.changedCalculation && _data.changedCalculation?.yearlyInterest !== _data.calculation.yearly_interest">
            <mat-card-header>
                <p matCardTitle>{{'Ratenanpassung nach Zinsänderung' | translate}}</p>
            </mat-card-header>
            <mat-card-content>
                <div fxFlex
                     fxLayout="row wrap"
                     fxLayoutGap="16px">
                    <fieldset fxFlex="0 1 calc(25% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Alter Nominalzins' | translate }}</legend>
                        <l7-text-block [truncate]="true"
                                       [text]="(_data.calculation.yearly_interest | number) + ' %'"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(25% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Neuer Nominalzins' | translate }}</legend>
                        <l7-text-block [truncate]="true"
                                       [text]="(_data.changedCalculation?.yearlyInterest | number) + ' %'"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(25% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Neue mtl. Rate' | translate }}</legend>
                        <l7-text-block [truncate]="true"
                                       [text]="_data.changedCalculation?.instalment | currency"></l7-text-block>
                    </fieldset>
                </div>
            </mat-card-content>
        </mat-card>
    </div>



</ng-container>
