/* libs/contractManagement/src/Views/Dialogs/UploadTakeoverDocumentDialog/UploadTakeoverDocumentDialogView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .without-margin {
  margin-bottom: 0 !important;
}
/*# sourceMappingURL=UploadTakeoverDocumentDialogView.css.map */
