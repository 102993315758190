// #region Imports

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IContractManagementOverviewInfo } from 'libs/contractManagement/src/Models/IContractManagementOverviewInfo';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContractOverviewRecalculationViewPresenter } from './ContractOverviewRecalculationViewPresenter';

// #endregion

/**
 * The `ContractOverviewRecalculationView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-contract-overview-recalculation-view',
    templateUrl: './ContractOverviewRecalculationView.html',
    styleUrls: ['./ContractOverviewRecalculationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ContractOverviewRecalculationViewPresenter,
    ],
    standalone: false,
})
export class ContractOverviewRecalculationView implements OnInit, OnDestroy, OnChanges {

    // #region Fields

    private readonly _presenter: ContractOverviewRecalculationViewPresenter;
    private readonly _fb: UntypedFormBuilder;
    private readonly _isSlb = new BehaviorSubject<boolean>(false);
    private _originalInstalment: number;
    private _overview: IContractManagementOverviewInfo | null;
    private _sentToAccounting: boolean;

    public form: UntypedFormGroup;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewRecalculationView` class.
     *
     * @public
     */
    public constructor(presenter: ContractOverviewRecalculationViewPresenter, fb: UntypedFormBuilder) {
        this._presenter = presenter;
        this._fb = fb;
        this.form = this._fb.group({
            yearlyInterest: [{ value: null, disabled: false }, [Validators.required]],
            instalment: [{ value: null, disabled: false }, [Validators.required]],
        });
    }

    // #endregion

    // #region Properties
    /**
     * Gets or sets the `details` property.
     *
     * @public
     */
    @Input()
    public set overview(value: IContractManagementOverviewInfo) {
        this._overview = value;
    }

    public get overview() {
        return this._overview;
    }

    @Input() public set sentToAccounting(value: boolean) {
        if (value) {
            this.form?.controls?.yearlyInterest.disable();
            this.form?.controls?.instalment.disable();
        }
        this._sentToAccounting = value;
    }

    public get sentToAccounting(): boolean {
        return this._sentToAccounting;
    }

    public get disableSave(): boolean {
        return this.form.invalid || this.form.controls.instalment.value === this._originalInstalment || this.sentToAccounting;
    }

    public get isSlb(): Observable<boolean> {
        return this._isSlb.asObservable();
    }

    /**
     * Returns the presenter of the `ContractOverviewRecalculationView`
     *
     * @public
     * @readonly
     */
    public get presenter(): ContractOverviewRecalculationViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.instalment.pipe(untilDestroyed(this))
            .subscribe(x => this.form.controls.instalment.patchValue(x));
        this._presenter.yearlyInterest.pipe(untilDestroyed(this))
            .subscribe(x => this.form.controls.yearlyInterest.patchValue(x));
        this._presenter.data.pipe(untilDestroyed(this))
            .subscribe((x) => {
                if (x) {
                    this.form.controls.yearlyInterest.setValidators([
                        Validators.required, Validators.min(x.instalmentCalculation.minYearlyInterest), Validators.max(x.instalmentCalculation.maxYearlyInterest),
                    ]);
                    this.form.controls.yearlyInterest.updateValueAndValidity();
                }
            });
        this._presenter.originalInstalment.pipe(untilDestroyed(this))
            .subscribe(x => this._originalInstalment = x);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.overview?.currentValue) {
            this._isSlb.next(changes.overview?.currentValue.slb);
            this._presenter.initialize(changes.overview?.currentValue.quote_id, changes.overview?.currentValue.contract_number, changes.overview?.currentValue.slb);
        }
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
