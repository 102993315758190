import { IUserInfoDto } from '@abcfinlab/api/login';
import { AuthService, UserService } from '@abcfinlab/auth';
import { AppConfig } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import markerSDK, { MarkerSdk } from '@marker.io/browser';
import { Observable, ReplaySubject, from } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { projectID, supportedRealms } from './ProjectConfig';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {

    private _widget: any = null;
    private readonly _stage: string | null = null;
    private readonly _userService: UserService;
    private readonly _authService: AuthService;
    private readonly _hasWidgetSubject: ReplaySubject<boolean>;

    public constructor(userService: UserService, authService: AuthService, appConfig: AppConfig) {
        this._authService = authService;
        this._userService = userService;
        this._stage = appConfig.get('stage');
        this._hasWidgetSubject = new ReplaySubject<boolean>(1);

        this._initPlugin().pipe(
            first(),
        ).subscribe((widget) => {
            this._widget = widget;
        });

        this._authService.hasSession.pipe(
            filter(hasSession => !hasSession && !!this._widget),
            first(),
        ).subscribe((x) => {
            // Unloads the feedback widget and detaches it from the dom / clears data
            this._widget.unload();
        });
    }

    /**
     * Returns the `hasWidget` property.
     *
     * @public
     * @readonly
     */
    public get hasWidget(): Observable<boolean> {
        return this._hasWidgetSubject.asObservable();
    }

    /**
     * Get the actual used project ID depending on the stage
     * @returns {string}        The project ID by stage
     */
    private _getProjectID(): string | null {
        const projectIdByStage = projectID.get(this._stage);

        return projectIdByStage
            ? atob(projectIdByStage)
            : null;
    }

    private _initPlugin(): Observable<MarkerSdk> {
        return this._userService.userInfo.pipe(
            filter(userInfo => supportedRealms.has(userInfo.realm)),
            first(),
            switchMap(userInfo => this._initWidget(userInfo)),
        );
    }

    /**
     * Construct the reporter data based on the given realm (object shape is unfortunately different)
     */
    private _getReporterDataFromUserinfo(userinfo: IUserInfoDto): { email: string; fullName: string } {
        const objectPath = supportedRealms.get(userinfo.realm);

        return {
            email: userinfo[objectPath].email,
            fullName: `${userinfo[objectPath].givenName} ${userinfo[objectPath].familyName}`,
        };
    }

    /**
     * Initialize the plugin with meta data and project ID
     * @param userInfo
     */
    private _initWidget(userInfo: IUserInfoDto): Observable<MarkerSdk> {
        // check if a project ID is set for the desired stage, if not prevent flow from making a call to the service
        if (!this._getProjectID()) {
            console.warn('FeedbackPluginService: Please provided a project ID.');
            return from(Promise.reject('Feedback-Plugin initialization failed: No projectID provided!'));
        }
        // if a project ID is set, move on with creating the widget
        const widget = markerSDK.loadWidget({
            project: this._getProjectID(),
            reporter: this._getReporterDataFromUserinfo(userInfo),
            customData: {
                META_DATA_STAGE: this._stage,
                META_DATA_REALM: userInfo.realm,
            },
        });

        return from(widget).pipe(
            tap(() => this._hasWidgetSubject.next(true)),
        );
    }

}
