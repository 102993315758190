<div fxLayout="column"
     fxLayoutGap="16px"
     *ngIf="(this.presenter.data | async) as data">
    <mat-card appearance="outlined" class="mat-card-outlined">
        <mat-card-header>
            <p matCardTitle>Angaben aus Kalkulation</p>
        </mat-card-header>
        <mat-card-content>
            <l7-summary [maxColumns]="3"
                        [minColumns]="2">
                <l7-summary-item [title]="'Nominalzins in Kalkulation' | translate"
                                 [content]="(data.calculation?.yearly_interest | number) + ' %'"></l7-summary-item>
                <l7-summary-item [title]="'Einstandskonditionen in Kalkulation' | translate"
                                 [content]="(data.calculation?.refinancing_rate | number) + ' %'"></l7-summary-item>
                <l7-summary-item [title]="'Aktuelle Einstandskonditionen' | translate"
                                 [content]="(data.refinancingInterest?.interest | number) + ' %'"></l7-summary-item>
                <l7-summary-item [title]="'Rate in Kalkulation' | translate"
                                 [content]="data.calculation?.instalment | currency"></l7-summary-item>
                <l7-summary-item *ngIf="(isSlb | async) === false && data.acknowledgement?.instalment"
                                 [title]="'Rate nach Objektwertänderung' | translate"
                                 [content]="data.acknowledgement?.instalment | currency"></l7-summary-item>

                <l7-summary-item *ngIf="(isSlb | async) && data.slb?.activeSlbResponse && data.slb?.activeSlbResponse.calculation?.instalment"
                                 [title]="'Rate nach Objektwertänderung' | translate"
                                 [content]="data.slb?.activeSlbResponse.calculation?.instalment | currency"></l7-summary-item>
            </l7-summary>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="mat-card-outlined">
        <mat-card-header>
            <p matCardTitle>Nominalzins (max. {{ data.instalmentCalculation?.maxYearlyInterest | number }} %) oder Rate anpassen</p>
            <p matCardSubtitle>Bitte wählen Sie entweder Nominalzins oder Rate. Beachten Sie, dass der jeweils andere Wert automatisch errechnet wird.</p>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="this.form"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="16px">
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{ 'Nominalzins in %' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           [options]="{suffix: ' %'}"
                           formControlName="yearlyInterest"
                           (keyup)="this.presenter.calculate('yearlyInterest', this.form.controls.yearlyInterest.value)"
                           l7MarkAsTouched
                           [markOnInit]="true"
                           l7SelectOnDblClick>
                    <mat-error *ngIf="form.controls.yearlyInterest.invalid && form.controls.yearlyInterest.hasError('required')">
                        {{'error.field_required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="form.controls.yearlyInterest.invalid && form.controls.yearlyInterest.hasError('max')">
                        {{'error.yearly_interest_max_value' | translate: {value: data.instalmentCalculation.maxYearlyInterest | number} }}
                    </mat-error>
                    <mat-error *ngIf="form.controls.yearlyInterest.invalid && form.controls.yearlyInterest.hasError('min')">
                        {{'error.yearly_interest_min_value' | translate: {value: data.instalmentCalculation.minYearlyInterest | number} }}
                    </mat-error>
                </mat-form-field>
                <div>
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Link'"></mat-icon>
                </div>
                <mat-form-field fxFlex
                                appearance="outline">
                    <mat-label>{{ 'Rate in €' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           formControlName="instalment"
                           (keyup)="this.presenter.calculate('instalment', this.form.controls.instalment.value)"
                           l7SelectOnDblClick>
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="end center"
                          fxLayoutGap="16px">
            <button mat-flat-button
                    [disabled]="this.disableSave || (this.presenter.disableSave | async)"
                    (click)="this.presenter.updateCalculation(this.form.controls.instalment.value, this.form.controls.yearlyInterest.value)"
                    [color]="'accent'">{{ 'Speichern' | translate }}</button>
        </mat-card-actions>
    </mat-card>
</div>
