/* libs/contractManagement/src/Views/contract-document-upload-view/contract-document-upload-view.component.scss */
:host .invoice-container {
  padding-top: 15px;
}
:host .invoice-container .disable-upload {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
}
:host .invoice-container .text-purchase-entry {
  margin-top: 15px;
  margin-bottom: 15px;
}
:host .invoice-container .invoice-confirmation-container div:first-child {
  padding-top: 15px;
  min-height: 125px;
  text-align: center;
}
:host .invoice-container .invoice-confirmation-container div:first-child p {
  margin-top: 10px;
}
/*# sourceMappingURL=contract-document-upload-view.component.css.map */
