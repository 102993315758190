/* libs/contractManagement/src/Components/kuev-comparison/kuev-comparison.component.scss */
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=kuev-comparison.component.css.map */
