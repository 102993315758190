/* libs/contractManagement/src/Views/ContractOverviewRecalculationView.scss */
:host {
  display: block;
  margin-top: 32px;
}
:host .icon-clearfix {
  padding-bottom: 1.34375em;
}
:host .mat-mdc-card {
  padding-bottom: 30px;
}
:host .mat-mdc-card-actions {
  padding-right: 10px;
}
/*# sourceMappingURL=ContractOverviewRecalculationView.css.map */
