<mat-card fxFlex
          fxLayout="column">
    <l7-text matCardTitle
             class="padding"
             [text]="'contract_management.widget.todo.title' | translate"
             [truncate]="true"></l7-text>
    <mat-card-content fxFlex
                      fxLayout="column"
                      fxLayoutGap="16px">
        <ng-container *ngIf="(this.presenter.isBusy | async) === false && (this.presenter.isEmpty | async) === false">
            <l7-tile *ngFor="let task of this.presenter.tasks | async"
                     (click)="this.presenter.onHande(task)">
                <div fxLayoutAlign="center"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="task.icon"
                              [color]="'primary'"></mat-icon>
                    <div fxFlex
                         fxLayoutAlign="start center">
                        <l7-text-block [wrap]="true"
                                       [text]="('contract_management.widget.todo.tasks.{0}.{1}' | format:task.condition:task.contractNumbers.length > 1 ? 'plural' : 'singular') | translate:({count: task.contractNumbers.length})"></l7-text-block>
                    </div>
                    <div fxLayoutAlign="center">
                        <mat-icon [fontSet]="'l7'"
                                  [color]="'primary'"
                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                    </div>
                </div>
            </l7-tile>
        </ng-container>

        <ng-container *ngIf="this.presenter.isBusy | async">
            <div fxFlex
                 fxLayoutAlign="center center">
                <mat-progress-spinner [diameter]="48"
                                      [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="this.presenter.isEmpty | async">
            <div fxFlex
                 fxLayoutAlign="center center">
                <l7-text-block [text]="'contract_management.widget.todo.empty' | translate"></l7-text-block>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>
