<ng-container *cdkBreakpoint="let bp">
    <l7-dialog-header [title]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.title' | translate:{companyName: data.companyName}"
                      [supportiveTitle]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.subTitle' | translate"
                      [closeable]="true"
                      (closed)="this.onClose(true)"></l7-dialog-header>
    <mat-dialog-content fxLayout="column"
                        fxLayoutGap="16px"
                        style="margin-bottom: 20px;">
        <div fxLayout="column">
            <l7-tile fxFlex
                     [inline]="true"
                     [attr.test-id]="'digitalSign'"
                     (click)="this.onSelect('digitalSign')">
                <div fxLayoutAlign="start start"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Globe'"
                              [color]="'primary'"></mat-icon>
                    <div fxFlex>
                        <l7-text-block [wrap]="true"
                                       [text]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.digitalSignTitle' | translate"></l7-text-block>
                        <l7-text-block [wrap]="true"
                                       [text]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.digitalSignSubTitle' | translate"></l7-text-block>
                    </div>
                    <div fxLayoutAlign="center center">
                        <mat-icon [fontSet]="'l7'"
                                  [color]="'primary'"
                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                    </div>
                </div>
            </l7-tile>
            <l7-tile fxFlex
                     [inline]="true"
                     [attr.test-id]="'uploadDelivery'"
                     (click)="this.onSelect('uploadDeliveryDocument')">
                <div fxLayoutAlign="start start"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Truck'"
                              [color]="'primary'"></mat-icon>
                    <div fxFlex>
                        <l7-text-block [wrap]="true"
                                       [text]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.uploadDeliveryTitle' | translate"></l7-text-block>
                        <l7-text-block [wrap]="true"
                                       [text]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.uploadDeliverySubTitle' | translate"></l7-text-block>
                    </div>
                    <div fxLayoutAlign="center center">
                        <mat-icon [fontSet]="'l7'"
                                  [color]="'primary'"
                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                    </div>
                </div>
            </l7-tile>
            <l7-tile fxFlex
                     [inline]="true"
                     [attr.test-id]="'takeoverDocumentUpload'"
                     (click)="this.onSelect('uploadTakeoverDocument')">
                <div fxLayoutAlign="start start"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Box_2'"
                              [color]="'primary'"></mat-icon>
                    <div fxFlex>
                        <l7-text-block [wrap]="true"
                                       [text]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.takeoverDocumentUploadTitle' | translate"></l7-text-block>
                        <l7-text-block [wrap]="true"
                                       [text]="'contract_management.retailers.contractDetails.dialogs.takeOverDocuments.takeoverDocumentUploadSubTitle' | translate"></l7-text-block>
                    </div>
                    <div fxLayoutAlign="center center">
                        <mat-icon [fontSet]="'l7'"
                                  [color]="'primary'"
                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                    </div>
                </div>
            </l7-tile>
        </div>
    </mat-dialog-content>
</ng-container>
