<h2 mat-dialog-title>{{ 'contract_management.takeover_confirmation.upload.title' | translate }}</h2>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px"
                    style="margin-bottom: 20px;">
        <l7-text-block [wrap]="true"
                       [readonly]="true"
                       [text]="'contract_management.takeover_confirmation.upload.content' | translate:{ param1: this.acknowledgementDate | date }">
        </l7-text-block>
        <l7-banner *ngIf="fileUploader.files?.length > 1"
                   [appearance]="'textual'"
                   [color]="'warn'"
                   [icon]="'Alert_Triangle'"
                   [content]="'contract_management.takeover_confirmation.upload.warning_message' | translate">
        </l7-banner>
        <l7-file-uploader #fileUploader
                          [orientation]="'vertical'"
                          [allowedFileTypes]="['pdf']"
                          [canUpload]="false"
                          (filesChanged)="this.onFilesChanged($event)">
        </l7-file-uploader>
</mat-dialog-content>
<mat-dialog-actions>
        <button mat-button
                color="accent"
                (click)="this.onClose(true)">{{'contract_management.takeover_confirmation.upload.reject' | translate }}</button>
        <button mat-flat-button
                color="accent"
                [disabled]="fileUploader.files?.length !== 1"
                (click)="this.onClose(false)">{{'contract_management.takeover_confirmation.upload.resolve' |
                translate}}</button>
</mat-dialog-actions>
