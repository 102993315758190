import {
    IManualProcessingDto,
    IManualProcessingReasonDto,
    ISalesChannelDto,
    ManualProcessingService,
} from '@abcfinlab/api/contract';
import { VerificationService } from '@abcfinlab/api/global';
import { Validators as CoreValidators, FeatureManager, Globals, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    ErrorHandlerService,
} from '../../../../../apps/l7/src/app/shared/services/errorHandler/error-handler.service';
import { ISettlementContractInformationInfo } from '../../Models/ISettlementContractInformationInfo';
import { ISettlementNavErrorDialogData, ISettlementNavErrorValue } from '../../Models/ISettlementNavErrorDialogData';
import { ManualProcessingErrorDialogView } from './ManualProcessingErrorDialogView';

@UntilDestroy()
@Component({
    selector: 'l7-manual-processing-dialog',
    templateUrl: './manual-processing-dialog.component.html',
    styleUrls: ['./manual-processing-dialog.component.scss'],
    standalone: false,
})
export class ManualProcessingDialogComponent implements OnInit {

    private readonly dataSubject: BehaviorSubject<ISettlementContractInformationInfo>;
    public data: Observable<ISettlementContractInformationInfo>;
    public manualProcessingForm: UntypedFormGroup;
    private readonly emails: Array<string> = [];
    public emailContractAudit: string;
    public reasons: Array<IManualProcessingReasonDto>;

    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;

    constructor(private readonly _manualProcessingService: ManualProcessingService,
        private readonly featureManager: FeatureManager,
        private readonly emailVerification: VerificationService,
        private readonly errorHandler: ErrorHandlerService,
        private readonly dialog: MatDialog,
        busyBox: BusyBoxService,
        translationFacade: TranslationFacade,
        toastService: ToastService,
        dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) data: ISettlementContractInformationInfo) {
        if (this.featureManager.stage() === 'prod') {
            const prodEmail = data.contract.salesChannel === ISalesChannelDto.Retailer ? Globals.contractManagementEmails.retailerProdEmail : Globals.contractManagementEmails.prodEmail;
            this.emails.push(prodEmail);
            this.emailContractAudit = prodEmail;
        } else {
            this.emails.push(Globals.contractManagementEmails.defaultEmail);
            this.emailContractAudit = Globals.contractManagementEmails.defaultEmail;
        }

        this.dataSubject = new BehaviorSubject(data);
        this.data = this.dataSubject.asObservable();
        this._toastService = toastService;
        this._dialogRef = dialogRef;
        this._translationFacade = translationFacade;
        this._busyBoxService = busyBox;
        this.manualProcessingForm = new UntypedFormGroup({
            reasons: new UntypedFormControl('', [Validators.required]),
            annotation_in_mail: new UntypedFormControl(''),
            email: new UntypedFormControl('', [CoreValidators.email()]),
        });
    }

    ngOnInit(): void {
        this._manualProcessingService.getManualProcessingReasons()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.reasons = data;
            });
    }

    sendToManualProcessing() {
        if (this.manualProcessingForm.valid && this.manualProcessingForm.get('email').value && this.manualProcessingForm.get('email').value !== '') {
            this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this.emailVerification.verifyEmail({ emailAddress: this.manualProcessingForm.get('email').value }).pipe(untilDestroyed(this)))
                .pipe(untilDestroyed(this))
                .subscribe((_) => {
                    if (!this.emails.includes(this.manualProcessingForm.get('email').value)) {
                        this.emails.push(this.manualProcessingForm.get('email').value);
                    }
                    this.submitData();
                },
                (_error) => {
                    const dialogMessage = this._translationFacade.instant(`error.${_error.error.error}`,
                        _error.error.error_params);
                    this.errorHandler.openDialogWithIdAndBody('error.email_not_valid', _error, dialogMessage);
                    this.errorHandler.errorDialogEmailVerification$
                        .pipe(untilDestroyed(this))
                        .subscribe((value) => {
                            if (value) {
                                if (!this.emails.includes(this.manualProcessingForm.get('email').value)) {
                                    this.emails.push(this.manualProcessingForm.get('email').value);
                                }
                                this.submitData();
                            }
                        });
                });
        } else {
            this.submitData();
        }
    }

    addEmails(emails: Array<string>): void {
        emails.forEach((email) => {
            if (!this.emails.includes(email)) {
                this.emails.push(email);
            }
        });
    }

    private submitData(): void {
        const annotation = this.manualProcessingForm.getRawValue().annotation_in_mail ? this.manualProcessingForm.getRawValue().annotation_in_mail : '';
        const reasons = this.manualProcessingForm.getRawValue().reasons;
        const contractNumber = this.dataSubject.value.row?.nav_contract_number;
        const body: IManualProcessingDto = {
            annotation_bo: annotation,
            reasons: reasons,
            emails: this.emails,
            annotation: this.dataSubject.value.contract?.annotation,
        };
        const params = { contractnumber: contractNumber, body: body };

        this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._manualProcessingService.saveManualProcessingReasons(params).pipe(untilDestroyed(this)), { id: 'saveManualProcessing' })
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                this._dialogRef.close(true);
                const arrayWithWarningOrError = Object.entries(x).filter(([key, value]: [string, ISettlementNavErrorValue]) => value.status === 'WARNING' || value.status === 'ERROR');
                if (arrayWithWarningOrError.length) {
                    this.dialog.open(ManualProcessingErrorDialogView, {
                        id: 'SettlementNavErrorDialogView',
                        disableClose: true,
                        closeOnNavigation: true,
                        height: '520px',
                        width: '620px',
                        data: {
                            contractNo: this.dataSubject.value.row.nav_contract_number,
                            submitDate: new Date(Date.now()),
                            errors: arrayWithWarningOrError.map(([key, value]) => ({ key: 'settlement.nav_errors.'.concat(key), value: value })),
                        } as ISettlementNavErrorDialogData,
                    });
                } else {
                    this._toastService.show(this._translationFacade.translate('settlement.state.messages.send.success'), 'success', 'long');
                }
            }, (_error) => {
                this._dialogRef.close();
                this._toastService.show(this._translationFacade.translate(`error.${_error.error.error_description}`), 'danger', 'long');
            });
    }

}
