// #region Imports

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InvoiceContractInformationViewPresenter } from './InvoiceContractInformationViewPresenter';

// #endregion

/**
 * The `InvoiceContractInformationView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-invoice-contract-information-view',
    templateUrl: './InvoiceContractInformationView.html',
    styleUrls: ['./InvoiceContractInformationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        InvoiceContractInformationViewPresenter,
    ],
    standalone: false,
})
export class InvoiceContractInformationView implements OnInit, OnDestroy {

    @Input() isReadonlyUser: boolean;

    // #region Fields

    private readonly _presenter: InvoiceContractInformationViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `InvoiceContractInformationView` class.
     *
     * @public
     */
    public constructor(presenter: InvoiceContractInformationViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `InvoiceContractInformationView`
     *
     * @public
     * @readonly
     */
    public get presenter(): InvoiceContractInformationViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    public patchSepaMandate(): void {
        this._presenter.createSepaMandate();
    }

    // #endregion

}
