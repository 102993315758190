// #region Imöorts

import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupLoginsDto, IUserGroupRetailerDto } from '@abcfinlab/auth';
import { RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { SETTLEMENT_OVERVIEW_ROUTE_PATH } from './SettlementRoutingModule';

// #endregion

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class SettlementNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `SettlementNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService, roleManager: RoleManager, translationFacade: TranslationFacade) {
        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if (user.groups.includes(IUserGroupLoginsDto.L7Backoffice) || user.groups.includes(IUserGroupRetailerDto.RetailerBackoffice) || user.groups.includes(IUserGroupLoginsDto.L7BackofficeReadonly) || user.groups.includes(IUserGroupRetailerDto.L7BackofficeReadonlyRetailer)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Abrechnungen'),
                        icon: 'Clipboard_Text',
                        order: 60,
                        route: SETTLEMENT_OVERVIEW_ROUTE_PATH,
                    });
                }
            });
    }

    // #endregion

}
