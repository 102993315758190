import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupRetailerDto } from '@abcfinlab/auth';
import { RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { RETAILER_CONFIGURATION_ROUTE_PATH, RETAILER_OVERVIEW_ROUTE_PATH } from '../Routing/RoutePaths';

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class AdministrationNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `AdministrationNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService,
        roleManager: RoleManager, translationFacade: TranslationFacade) {
        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if (user.groups.includes(IUserGroupRetailerDto.FinanceAdmin)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Fachhändlerverwaltung'),
                        icon: 'Users',
                        order: 80,
                        route: RETAILER_OVERVIEW_ROUTE_PATH,
                    });
                }
                if (user.groups.includes(IUserGroupRetailerDto.RetailerAdmin)) {
                    presenterService.setNavigation({
                        label: translationFacade.instant('Konfiguration'),
                        icon: 'Gear',
                        order: 90,
                        route: RETAILER_CONFIGURATION_ROUTE_PATH,
                    });
                }
            });
    }

    // #endregion

}
