// #region Imports

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MoneyLaunderingComponent } from '../../../../../apps/l7/src/app/private/pages/money-laundering/money-laundering.component';

// #endregion

/**
 * @public
 */
export const GWG_ROUTES: Routes = [
    {
        path: 'gwg',
        component: MoneyLaunderingComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./gwg.module').then(mod => mod.GwgModule),
            },
        ],
        // loadChildren: './pages/money-laundering#MoneyLaunderingComponent'
    },
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(GWG_ROUTES),
    ],
    exports: [RouterModule],
})
export class GwgRoutingModule {
}
