import { RetailerContractSuccessView } from '@abcfinlab/presentation';
import { Route } from '@angular/router';
import { AuthGuard } from '../../../../apps/l7/src/app/shared/guards/auth.guard';
import { getLesseeResolver } from '../../../quote/src/Resolvers/Resolvers';
import { RetailerContractDetailsResolver } from '../Resolvers/RetailerContractDetailsResolver';
import {
    RetailerContractDetailsView,
} from '../Views/retailer-contract-managment/retailer-contract-details-view/RetailerContractDetailsView';
import {
    CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH, RETAILER_CONTRACT_SIGNED_SUCCESS_PATH,
} from './RoutePaths';

/**
 * @internal
 */
export const CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE: Route = {
    path: `${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}/:id`,
    component: RetailerContractDetailsView,
    resolve: {
        contractDetails: RetailerContractDetailsResolver,
    },
    canActivate: [AuthGuard],
};

/**
 * @internal
 */
export const RETAILER_CONTRACT_SIGNED_SUCCESS_ROUTE: Route = {
    path: `${RETAILER_CONTRACT_SIGNED_SUCCESS_PATH}/:id`,
    component: RetailerContractSuccessView,
    resolve: {
        contractDetails: RetailerContractDetailsResolver,
        lessee: getLesseeResolver,
    },
    canActivate: [AuthGuard],
};
