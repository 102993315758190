<l7-page>
    <l7-page-header [title]="presenter.userName | async"
                    [hasBackNavigation]="true">
    </l7-page-header>
    <l7-page-content>
        <form fxLayout="column"
              fxLayoutGap="24px"
              [formGroup]="presenter.form">
            <mat-card appearance="outlined"
                      class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title>{{ 'Nutzerdaten' | translate }}</mat-card-title>
                    <mat-card-subtitle>{{ 'Bitte geben Sie die Nutzerdaten ein' | translate }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap="16px">
                        <mat-form-field fxFlex
                                        appearance="outline">
                            <mat-label>{{ 'Vorname' | translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   [formControlName]="'givenName'">
                            <mat-error *ngIf="presenter.form.get('givenName').hasError('required')">
                                <p>{{ 'administration.retailers.create.input.givenName.error.required' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex
                                        appearance="outline">
                            <mat-label>{{ 'Nachname' | translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   [formControlName]="'familyName'">
                            <mat-error *ngIf="presenter.form.get('familyName').hasError('required')">
                                <p>{{ 'administration.retailers.create.input.familyName.error.required' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap="16px">
                        <mat-form-field fxFlex
                                        class="mat-error-from-color"
                                        appearance="outline"
                                        [color]="presenter.form.get('email').hasError('emailInvaid') ? 'warning': ''">
                            <mat-label>{{ 'E-Mail' | translate }}</mat-label>
                            <input matInput
                                   readonly
                                   disabled
                                   required
                                   type="email"
                                   [formControlName]="'email'">
                            <div *ngIf="(presenter.form.get('email').statusChanges | async) === 'PENDING'"
                                 matSuffix>
                                <mat-progress-spinner [diameter]="16"
                                                      [strokeWidth]="2"
                                                      [color]="'primary'"
                                                      [mode]="'indeterminate'"></mat-progress-spinner>
                            </div>
                            <mat-error *ngIf="presenter.form.get('email').hasError('required')">
                                <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('email').hasError('email')">
                                <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('email').hasError('emailInvaid')">
                                <p>{{ 'administration.retailers.create.input.email.error.emailInvaid' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex
                                        appearance="outline">
                            <mat-label>{{ 'Telefonnummer' | translate }}</mat-label>
                            <input matInput
                                   type="tel"
                                   [formControlName]="'phoneNumber'">
                            <mat-error *ngIf="presenter.form.get('phoneNumber').hasError('required')">
                                <p>{{ 'administration.retailers.create.input.phoneNumber.error.required' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('phoneNumber').hasError('invalidPhoneNumber')">
                                <p>{{ 'Die Telefonnummer ist ungültig' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('phoneNumber').hasError('maxLengthExceeded')">
                                <p>{{ 'Die Telefonnummer ist zu lang' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row"
                         fxLayoutGap="16px">
                        <mat-form-field fxFlex
                                        appearance="outline">
                            <mat-label>{{ 'Mobilnummer' | translate }}</mat-label>
                            <input matInput
                                   type="tel"
                                   [formControlName]="'mobileNumber'">
                            <mat-error *ngIf="presenter.form.get('mobileNumber').hasError('invalidPhoneNumber')">
                                <p>{{ 'Die Mobilnummer ist ungültig' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('phoneNumber').hasError('maxLengthExceeded')">
                                <p>{{ 'Die Mobilnummer ist zu lang' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                        <span fxFlex
                              fxHide.lt-sm></span>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined"
                      class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title>{{ 'Nutzerrechte' | translate }}</mat-card-title>
                    <mat-card-subtitle>{{ 'Bitte weisen sie dem Nutzer die entsprechenden Rechte zu' | translate }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <l7-checkbox-group [formControlName]="'groups'">
                        <div fxLayout="column"
                             fxLayoutGap="16px">
                            <mat-checkbox [value]="presenter.userGroup.RetailerAdmin"
                                          [disabled]="presenter.isLastAdmin | async">
                                <p>{{ 'Nutzerverwaltung und Einstellungen (Admin)' | translate }}</p>
                            </mat-checkbox>
                            <mat-checkbox [value]="presenter.userGroup.RetailerSales">
                                <p>{{ 'Vertragsabschluss & Verwaltung eigener Verträge (Vertrieb)' | translate }}</p>
                            </mat-checkbox>
                            <mat-checkbox [value]="presenter.userGroup.RetailerContractManagement">
                                <p>{{ 'Verwaltung aller Verträge (Verwaltung)' | translate }}</p>
                            </mat-checkbox>
                        </div>
                    </l7-checkbox-group>
                    <mat-error *ngIf="presenter.form.get('groups').hasError('required')"
                               class="mat-error-standalone">
                        <p>{{ 'administration.retailers.create.input.groups.error.required' | translate }}</p>
                    </mat-error>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined"
                      class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title>{{ 'Status des Nutzers' | translate }}</mat-card-title>
                    <mat-card-subtitle>{{ 'Bitte wählen Sie den Status des Nutzers. Wenn Sie den Nutzer deaktivieren, hat dieser keinen Zugriff mehr auf Lease Seven.' | translate }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-slide-toggle [disabled]="presenter.isLastAdmin | async"
                                      [formControlName]="'active'">{{ 'aktiv' | translate }}</mat-slide-toggle>
                </mat-card-content>
            </mat-card>
        </form>
    </l7-page-content>
    <l7-spacer [density]="'comfortable'"
               [thickness]="'top'"></l7-spacer>
    <l7-page-footer fxLayoutAlign="end"
                    fxLayout="row"
                    fxLayoutGap="16px">
        <button mat-button
                [color]="'accent'"
                (click)="presenter.onSubmit(true)">Abbrechen</button>
        <button mat-flat-button
                [color]="'accent'"
                (click)="presenter.onSubmit(false)">Speichern</button>
    </l7-page-footer>
</l7-page>