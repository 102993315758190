import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointService } from '../../../../../../libs/layout/src/Services/BreakpointService';
import { RetailerContractsOverviewViewPresenter } from './retailer-contracts-overview-view-presenter';

@UntilDestroy()
@Component({
    selector: 'l7-retailer-contracts-overview-view',
    templateUrl: './retailer-contracts-overview-view.component.html',
    styleUrls: ['./retailer-contracts-overview-view.component.scss'],
    providers: [
        RetailerContractsOverviewViewPresenter,
    ],
    standalone: false,
})
export class RetailerContractsOverviewViewComponent implements OnInit {

    public isMobile: boolean;
    public activeView: 'CARD' | 'TABLE' = 'CARD';

    constructor(private readonly _presenter: RetailerContractsOverviewViewPresenter, private readonly _breakpointService: BreakpointService) {

    }

    public get presenter(): RetailerContractsOverviewViewPresenter {
        return this._presenter;
    }

    public ngOnInit(): void {
        this.presenter.initialize();

        this._breakpointService.isDesktopViewport$.pipe(
            untilDestroyed(this),
        ).subscribe((isDesktopMode) => {
            if (isDesktopMode) {
                this.isMobile = false;
                this.activeView = 'TABLE';
            } else {
                this.isMobile = true;
                this.activeView = 'CARD';
            }
        });
    }

    // public ngAfterViewInit(): void {
    //     this.presenter.initialize();
    // }

    public changeViewType(type: 'CARD' | 'TABLE'): void {
        this.activeView = type;
    }

}
