/* libs/backoffice/src/Views/Dialogs/SettlementNavErrorDialogView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
  text-align: initial;
}
:host .dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 0 24px;
  height: 65px;
}
/*# sourceMappingURL=SettlementNavErrorDialogView.css.map */
