// #region Imports

import { IDashboardTaskDto, RetailerContractService } from '@abcfinlab/api/contract';
import { once } from '@abcfinlab/core';
import { BusyObserver } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH } from '../../Modules/ContractManagementRoutingModule';
import { CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH } from '../../Routing/RoutePaths';

// #endregion

/**
 * @private
 */
interface ITaskMeta {
    key: string;
    icon: string;
}

/**
 * A mapping list to decide which backend item has which icon.
 *
 * @private
 */
const TASK_ITEM_MAP: Array<ITaskMeta> = [
    {
        key: 'UPLOAD_INVOICE',
        icon: 'Newspaper_Filled',
    },
    {
        key: 'ACKNOWLEDGEMENT',
        icon: 'File_Upload_Filled',
    },
];

/**
 * The presenter of the {@link TodoWidgetView} view.
 *
 * @internal
 */
@Injectable()
export class TodoWidgetViewPresenter {

    // #region Fields

    private readonly _busyObserver: BusyObserver;
    private readonly _router: Router;
    private readonly _retailerContractService: RetailerContractService;
    private readonly _tasksSubject: BehaviorSubject<Array<ITaskMeta & IDashboardTaskDto>>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `TodoWidgetViewPresenter` class.
     *
     * @public
     */
    public constructor(busyObserver: BusyObserver, router: Router, retailerContractService: RetailerContractService) {
        this._busyObserver = busyObserver;
        this._router = router;
        this._retailerContractService = retailerContractService;
        this._tasksSubject = new BehaviorSubject([]);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `tasks` property.
     *
     * @public
     * @readonly
     */
    public get tasks(): Observable<Array<ITaskMeta & IDashboardTaskDto>> {
        return this._tasksSubject.asObservable();
    }

    /**
     * Returns the `isBusy` property.
     *
     * @public
     * @readonly
     */
    public get isBusy(): Observable<boolean> {
        return this._busyObserver.isBusy;
    }

    /**
     * Returns the `isEmpty` property.
     *
     * @public
     * @readonly
     */
    public get isEmpty(): Observable<boolean> {
        return combineLatest([this.tasks, this.isBusy]).pipe(
            map(([tasks, isBusy]) => tasks.length === 0 && !isBusy),
        );
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._busyObserver.busy(true);

        once(this._retailerContractService.getDashboardData(), (data) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const dataWithMeta = data.dashboardTasks.map(x => ({
                condition: x.condition,
                contractNumbers: x.contractNumbers,
                ...TASK_ITEM_MAP.find(y => y.key === x.condition),
            }));

            this._tasksSubject.next(dataWithMeta);
            this._busyObserver.busy(false);
        }, () => {
            this._busyObserver.busy(false);
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._busyObserver.busy(false);
    }

    /**
     * @protected
     */
    public onHande(item: IDashboardTaskDto): void {
        if (item.contractNumbers.length !== 1) {
            void this._router.navigate([`presentation/${RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH}`], { queryParams: { condition: [item.condition] } });
        } else {
            void this._router.navigate([`presentation/${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}/${item.contractNumbers[0]}`]);
        }
    }

    // #endregion

}
