import {
    AcknowledgementService, ContractsDocumentService,
    IAcknowledgementWithoutSigningDto,
    IProcessingStatusDto,
    IPurchaseEntryDto,
    ISaleAndLeaseBackCodeDto,
    ISalesChannelDto, ISlbGetResponseDto, SlbService,
} from '@abcfinlab/api/contract';
import { Validators as CoreValidators, once, TranslationFacade } from '@abcfinlab/core';
import { formValuesAreEqual, getDirtyValues } from '@abcfinlab/presentation';
import { ToastService } from '@abcfinlab/ui';
import { DecimalPipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import _ from 'underscore';
import { UploadDocumentType } from '../../../../apps/l7/src/app/models/enums/UploadDocumentType.enum';

@UntilDestroy()
@Component({
    selector: 'l7-settlement-acknowledgement-form',
    templateUrl: './acknowledgement-form.component.html',
    styleUrls: ['./acknowledgement-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DecimalPipe],
    standalone: false,
})
export class AcknowledgementFormComponent implements OnInit, AfterViewInit, DoCheck {

    @Input() ackDto: IAcknowledgementWithoutSigningDto;

    @Input() documentType: UploadDocumentType;
    @Input() set ackData(value) {
        this.originalValues = value;
        if (value) {
            Object.keys(value).forEach((name) => {
                if (this.acknowledgementForm.controls[name]) {
                    this.acknowledgementForm.controls[name].patchValue(value[name], { onlySelf: true, emitEvent: false });
                }
            });
            this.acknowledgementForm.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
    }

    @Input() contractStatus: IProcessingStatusDto;

    @Input() purchaseEntry: IPurchaseEntryDto;

    @Input() quoteId: string;

    @Input() navStatus: number;

    @Input() salesChanel: ISalesChannelDto;

    @Input() slbData: ISlbGetResponseDto;

    @Input() public isReadonlyUser: boolean;

    @Input() taxNumberLessee: string;

    @Input() documentId: string;

    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

    acknowledgementForm: UntypedFormGroup;

    minDate: Date;

    areValuesChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private originalValues: IAcknowledgementWithoutSigningDto;

    private readonly _confirmLabel: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public get confirmLabel(): Observable<string> {
        return this._confirmLabel.asObservable();
    }

    constructor(
        private readonly acknowledgementService: AcknowledgementService,
        formBuilder: FormBuilder,
        private readonly slbService: SlbService,
        private readonly contractsDocumentService: ContractsDocumentService,
        private readonly notification: ToastService,
        private readonly translationFacade: TranslationFacade,
        private readonly decimalPipe: DecimalPipe,
    ) {
        this.acknowledgementForm = formBuilder.group({
            acknowledgement_date: [{ value: null, disabled: false }, [Validators.required]],
            serial_number: [{ value: null, disabled: false }, [Validators.required]],
            chassis_number: [{ value: false, disabled: false }],
            object_name: [{ value: null, disabled: true }],
            lessee_name: [{ value: null, disabled: true }],
            vendor_name: [{ value: null, disabled: true }],
            lessee_address: [{ value: null, disabled: true }],
            condition: [{ value: null, disabled: true }],
            year_of_construction: [{ value: null, disabled: false }, [
                Validators.required,
                Validators.min(this.years[this.years.length - 1]),
                Validators.max(this.years[0]),
            ]],
            invoice_number: [{ value: null, disabled: false }],
            purchase_price: [{ value: null, disabled: true }],
            iban: [{ value: null, disabled: true }],
            tax_number_lessee: [{ value: null, disabled: true }],
            confirmed: [{ value: false, disabled: false }],
        });
        this.decimalPipe = decimalPipe;
    }

    public get years(): Array<number> {
        const result = new Array<number>();
        const currentYear = new Date(Date.now()).getFullYear();
        let offset = (currentYear - 1900);
        while (offset--) {
            result.push(currentYear - offset);
        }
        return result.reverse();
    }

    ngOnInit(): void {
        const disabledFields = ['object_name', 'lessee_name', 'vendor_name', 'lessee_address', 'condition', 'iban', 'purchase_price', 'invoice_number'];
        const translationKey = this.documentType === UploadDocumentType.KUEV ? 'settlement.dialogs.kuev.checkboxConfirmLabel' : 'settlement.dialogs.ack.checkboxConfirmLabel';

        this._confirmLabel.next(this.translationFacade.instant(translationKey));
        if (this.documentType === UploadDocumentType.KUEV) {
            disabledFields.splice(-1, 1);
            this.acknowledgementForm.controls.invoice_number.addValidators([Validators.required]);
            if (this.slbData.code === ISaleAndLeaseBackCodeDto.UnechtlnV2) {
                this.acknowledgementForm.controls.invoice_number.addAsyncValidators(CoreValidators.validateInvoiceNumberUniqueAsync(this.contractsDocumentService, this.documentId));
            }
            this.acknowledgementForm.controls.invoice_number.patchValue(this.slbData.activeSlbResponse.billingNumber, { onlySelf: true, emitEvent: false });
            this.acknowledgementForm.controls.purchase_price.patchValue(this.decimalPipe.transform(this.slbData.activeSlbResponse.calculation.totalLeasingValueNet, '.2-2'), { onlySelf: true, emitEvent: false });
            this.acknowledgementForm.controls.iban.patchValue(this.slbData.activeSlbResponse.bankAccountDto?.iban, { onlySelf: true, emitEvent: false });
            this.acknowledgementForm.controls.year_of_construction.disable();
            this.acknowledgementForm.controls.tax_number_lessee.patchValue(this.taxNumberLessee);
            this.acknowledgementForm.updateValueAndValidity();
        }
        this.acknowledgementForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((values) => {
                const changedValues = getDirtyValues(this.acknowledgementForm);
                if (changedValues) {
                    const originalValuesWithoutDisableFields = _.omit(
                        this.originalValues,
                        disabledFields,
                    );
                    this.areValuesChanged$.next(!formValuesAreEqual(originalValuesWithoutDisableFields, values));
                }
            });

        this.minDate = new Date(this.purchaseEntry?.creation_date);
    }

    ngAfterViewInit() {
        if ((this.contractStatus !== 'BILLABLE' && this.contractStatus !== 'TO_BE_DISCUSSED') || this.navStatus >= 50) {
            this.acknowledgementForm.disable();
        }
    }

    ngDoCheck() {
        if (this.acknowledgementForm.controls.chassis_number.value) {
            this.acknowledgementForm.controls.serial_number.setValidators([Validators.required, Validators.minLength(17), Validators.maxLength(17), Validators.pattern(/^[^ioqIOQ]+$/)]);
            this.acknowledgementForm.controls.serial_number.updateValueAndValidity();
            this.acknowledgementForm.controls.serial_number.markAsTouched();
        }
    }

    public changedChassisNumber(evt: MatCheckboxChange): void {
        if (evt.checked) {
            this.acknowledgementForm.controls.serial_number.setValidators([Validators.required, Validators.minLength(17), Validators.maxLength(17)]);
        } else {
            this.acknowledgementForm.controls.serial_number.setValidators([Validators.required]);
        }
        this.acknowledgementForm.controls.serial_number.updateValueAndValidity();
        this.acknowledgementForm.controls.serial_number.markAsTouched();
    }

    public save() {
        this.areValuesChanged$.next(false);
        if (this.documentType === UploadDocumentType.KUEV) {
            const body = {
                acknowledgementDate: this.acknowledgementForm.getRawValue().acknowledgement_date,
                chassisNumber: this.acknowledgementForm.getRawValue().chassis_number,
                objectSerialNumber: this.acknowledgementForm.getRawValue().serial_number,
                billingNumber: this.acknowledgementForm.getRawValue().invoice_number,
                slbConfirmed: this.acknowledgementForm.getRawValue().confirmed,
            };
            once(this.slbService.updateActiveSlbForAccounting({ contractId: this.ackDto.id, body }),
                (_) => {
                    this.originalValues = this.acknowledgementForm.getRawValue();
                    this.notification.showSuccess(this.translationFacade.instant('global.save_successful'));
                    this.closeDialog.emit(true);
                },
                (_) => {
                    this.notification.showError(this.translationFacade.instant('error.generic_error'));
                    this.areValuesChanged$.next(true);
                });
            return;
        }
        const subscription = this.acknowledgementService.save({
            body: {
                ...this.ackDto,
                ...this.acknowledgementForm.getRawValue(),
                ...{ leasing_quote_id: this.quoteId },
            },
        })
            .subscribe(() => {
                this.originalValues = this.acknowledgementForm.getRawValue();
                this.notification.showSuccess(this.translationFacade.instant('global.save_successful'));
                this.closeDialog.emit(true);
            },
            (_error) => {
                this.notification.showError(this.translationFacade.instant('error.generic_error'));
                this.areValuesChanged$.next(true);
            },
            () => {
                subscription.unsubscribe();
            });
    }

    protected readonly ISaleAndLeaseBackCodeDto = ISaleAndLeaseBackCodeDto;

}
