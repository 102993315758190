// #region Imports

import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../apps/l7/src/app/shared/guards/auth.guard';
import {
    CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE,
    RETAILER_CONTRACT_SIGNED_SUCCESS_ROUTE,
} from '../Routing/Routes';
import { ContractOverviewView } from '../Views/ContractOverviewView';
import { RetailerContractsOverviewViewComponent } from '../Views/retailer-contract-managment/retailer-contracts-overview-view/retailer-contracts-overview-view.component';

// #endregion

/**
 * @internal
 */
export const CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH: string = 'contracts/overview';

/**
 * @internal
 */
export const RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH: string = 'retailer/contracts/overview';

/**
 * @internal
 */
export const CONTRACT_MANAGEMENT_OVERVIEW_ROUTE: Route = {
    path: CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH,
    component: ContractOverviewView,
    canActivate: [AuthGuard],
};

export const RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE: Route = {
    path: RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE_PATH,
    component: RetailerContractsOverviewViewComponent,
    canActivate: [AuthGuard],
};

/**
 * @public
 */
export const CONTRACT_MANAGEMENT_ROUTES: Routes = [
    CONTRACT_MANAGEMENT_OVERVIEW_ROUTE,
    CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE,
    CONTRACT_MANAGEMENT_OVERVIEW_ROUTE,
    RETAILER_CONTRACT_MANAGEMENT_OVERVIEW_ROUTE,
    RETAILER_CONTRACT_SIGNED_SUCCESS_ROUTE,
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(CONTRACT_MANAGEMENT_ROUTES),
    ],
    exports: [RouterModule],
})
export class ContractManagementRoutingModule {
}
