/* libs/contractManagement/src/Views/ContractOverviewBillingInformationView.scss */
:host {
  display: block;
  padding-top: 16px;
}
:host mat-radio-button + mat-radio-button {
  margin-top: initial;
}
:host h4 {
  margin: unset;
}
:host mat-radio-button {
  margin-left: 1.25rem;
}
:host mat-radio-button .radio-label {
  white-space: normal;
}
:host mat-radio-button .radio-label ~ mat-form-field {
  margin-left: 8px;
}
:host mat-form-field.appearanceFill ::ng-deep {
  height: 62px;
}
:host mat-form-field.appearanceFill ::ng-deep .mdc-line-ripple::before,
:host mat-form-field.appearanceFill ::ng-deep .mdc-line-ripple::after,
:host mat-form-field.appearanceFill ::ng-deep .mat-mdc-form-field-focus-overlay {
  bottom: -9px;
}
/*# sourceMappingURL=ContractOverviewBillingInformationView.css.map */
