<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px" *ngIf="loading">
    <l7-spacer [thickness]="'all'" [density]="'comfortable'">
        <mat-spinner></mat-spinner>
    </l7-spacer>
</div>

<div class="purchase-entry-form-container"
     *ngIf="!purchaseEntry?.addresses && purchaseEntry?.status === purchaseEntryStatus.Open && !loading">
  <div *ngIf="needsUpload">
    <div fxLayout="row">
      <h2 fxFlex.lt-md="100"
          fxFlex="100">{{'contract_management.purchase_entry.vendor_offer_title' | translate}}
        <mat-icon [inline]="true"
                  [fontIcon]="'Question'"
                  [color]="'primary'"
                  [fontSet]="'l7'"
                  class="l7-icon-design"
                  [matTooltipClass]="'tooltip-text'"
                  [matTooltip]="('contract_management.purchase_entry.vendor_offer_upload_tooltip' | translate )">
        </mat-icon>
      </h2>
    </div>
    <div class="text-vendor-offer"
         fxLayout="row">
      <p fxFlex.lt-md="100"
         fxFlex="100">{{'contract_management.purchase_entry.vendor_offer_text' | translate}}
      </p>
    </div>
    <l7-file-uploader [useUploadButton]="true"
                      [maxFiles]="1"
                      [allowedFileTypes]="['pdf']"
                      [canUpload]="(canUpload$ | async)"
                      (filesChanged)="filesToUpload($event)"
                      (filesForUpload)="uploadFiles($event)">
    </l7-file-uploader>
  </div>
  <div fxLayout="row">
    <div fxFlex.lt-md="100"
         fxFlex="70"
         style="margin: 16px 0">
      <span class="object-warning"
            *ngIf="purchaseEntry?.object_value">
        <mat-icon [inline]="true"
                  [fontSet]="'l7'"
                  [fontIcon]="'Alert_Circle'"></mat-icon>
        {{
        'contract_management.purchase_entry.object_value_changed' |
        translate: {
        param1: quoteDetails.quote_calculation.quote_calculation.total_leasing_value | currency: 'EUR',
        param2: purchaseEntry.object_value | currency: 'EUR'
        }
        }}
      </span>
    </div>
    <div fxFlex.lt-md="100"
         fxFlex="30"
         class="object-link">
      <mat-icon [inline]="true"
                [fontSet]="'l7'"
                [fontIcon]="'Edit_3'"class="l7-icon-design"></mat-icon>
      <a (click)="openChangeObjectValueDialog()">{{'contract_management.purchase_entry.link_object_value' | translate}}</a>
    </div>
  </div>
  <div fxLayout="row">
    <h2 fxFlex.lt-md="100"
        fxFlex="100">{{'contract_management.purchase_entry.form_title' | translate}}</h2>
  </div>
  <div class="text-purchase-entry"
       fxLayout="row">
    <p fxFlex.lt-md="100"
       fxFlex="100">{{'contract_management.purchase_entry.form_text' | translate}}</p>
  </div>
  <form [formGroup]="purchaseEntryForm">
    <l7-email-input-with-chips [formGroup]="purchaseEntryForm"
                               (emailList)="emails = $event"></l7-email-input-with-chips>
    <div fxLayout="row">
      <div style="display:inline-block;"
           [matTooltip]="('contract_management.purchase_entry.button_tooltip' | translate)"
           [matTooltipDisabled]="!needsUpload"
           matTooltipClass="tooltip-alert">
        <button mat-flat-button
                [color]="'accent'"
                (click)="showConfirmationModal()"
                [attr.test-id]="'ButtonCreatePurchaseEntry'"
                [disabled]="(!purchaseEntryForm.valid && !emails.length) || needsUpload">
          {{'contract_management.purchase_entry.button_label' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="purchaseEntry && purchaseEntry?.status !== purchaseEntryStatus.Open && !loading">
  <div class="purchase-entry-container">
    <div fxLayout="row"
         style="margin: 16px 0">
      <span fxFlex="100"
            class="object-warning"
            *ngIf="purchaseEntry?.object_value">
        <mat-icon [inline]="true"
                  [fontSet]="'l7'"
                  [fontIcon]="'Alert_Circle'"></mat-icon>
        {{
        'contract_management.purchase_entry.object_value_changed' |
        translate: {
        param1: quoteDetails.quote_calculation.quote_calculation.total_leasing_value | currency: 'EUR',
        param2: purchaseEntry.object_value | currency: 'EUR'
        }
        }}
      </span>
    </div>
    <div fxLayout="row">
      <div fxFlex.lt-md="100"
           fxFlex="100">
        <mat-icon
                  [fontSet]="'l7'"
                  [color]="'success'"
                  [fontIcon]="'Check_Circle'"
                  *ngIf="purchaseEntry?.addresses && purchaseEntry?.status === purchaseEntryStatus.Created"></mat-icon>
        <p *ngIf="purchaseEntry.addresses && purchaseEntry.status === purchaseEntryStatus.Created">
          {{('contract_management.purchase_entry.text' | translate:
          {param1: purchaseEntry.creation_date | date: 'shortDate',
          param2: purchaseEntry.addresses.toString().replace(',', ', ')})}}
        </p>
        <mat-icon [inline]="true"
                  [fontSet]="'l7'"
                  [color]="'danger'"
                  [fontIcon]="'Cross_Circle'"
                  *ngIf="purchaseEntry.status === purchaseEntryStatus.Portal"></mat-icon>
        <p *ngIf="purchaseEntry.status === purchaseEntryStatus.Portal">
          {{('contract_management.purchase_entry.text_portal' | translate)}}
        </p>
      </div>
    </div>
  </div>
</ng-container>
