/* libs/administration/src/Views/RetailerConfigurationOverviewView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host h4 {
  font-size: 16px;
}
:host .image-container {
  padding: 24px;
  border-radius: 5px;
  border: solid 1px #72787f;
}
.contactMail {
  color: #3891a6;
}
.contactInfo {
  color: #596068;
}
.contactBanner {
  color: #1b7e9a;
  width: 28px;
}
.bicInfo {
  color: #1ebc83;
}
.mat-mdc-tab-group {
  width: 100%;
}
.info-row {
  margin-top: 6px;
}
.secondary-text {
  color: rgba(26, 35, 45, 0.54);
}
.provision-input {
  width: calc(70% - 16px);
}
.banner-label-container {
  box-sizing: inherit;
  display: inherit;
  flex-direction: column;
  justify-content: center;
}
.banner-label-container mat-icon {
  font-size: 18px;
}
.banner-label-container .banner-content {
  box-sizing: inherit;
}
.mat-column-email {
  flex: 0 0 30%;
}
.mat-column-groups {
  flex: 0 0 20%;
}
.mat-column-phoneNumber {
  flex: 0 0 20%;
}
.banner-border {
  border: 1px solid #1b7e9a;
  border-left: 8px solid #1b7e9a;
}
/*# sourceMappingURL=RetailerConfigurationOverviewView.css.map */
