/* libs/contractManagement/src/Views/Dialogs/takeover-documentation/takeoverDocumentationDialogView.scss */
:host .mat-icon {
  height: 24px;
  width: 24px;
  font-size: 24px;
}
:host .mat-mdc-card {
  min-height: 75px;
  height: 172px;
}
:host .block-header {
  height: 16px;
}
:host .block-header .mat-icon {
  height: 24px;
  width: 24px;
  font-size: 24px;
  color: #71879c;
}
/*# sourceMappingURL=takeoverDocumentationDialogView.css.map */
