/* libs/contractManagement/src/Views/contract-overview-kuev-view/ContractOverviewKuevView.scss */
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=ContractOverviewKuevView.css.map */
