<div fxLayout="column"
     fxLayoutGap="16px"
     *ngIf="this.presenter.data | async">
    <div *ngIf="this.ready"
         fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="16px"
         class="ready-container">
        <mat-icon [fontSet]="'l7'"
                  [fontIcon]="'Check_Circle'"></mat-icon>
        <ng-container *ngIf="documents[0]?.type === 'ACKNOWLEDGEMENT_SIGNED'">
            <div fxLayout="column">
                <l7-text-block [text]="(this.presenter.data | async).acknowledgement.signing_dto?.signing_date | date: 'shortDate'"></l7-text-block>
                <l7-text-block [text]="'contract_management.takeover_confirmation.ready.title_remote_signed' | translate:{ givenName: (this.presenter.data | async).acknowledgement.signing_dto?.signed_by_first_name, familyName: (this.presenter.data | async).acknowledgement.signing_dto?.signed_by_last_name }">
                </l7-text-block>
            </div>
        </ng-container>
        <ng-container *ngIf="documents[0]?.type === 'ACKNOWLEDGEMENT_SIGNED_PAPER'">
            <div fxLayout="column">
                <l7-text-block [text]="documents[0]?.creationDate | date: 'shortDate'"></l7-text-block>
                <l7-text-block [text]="'contract_management.takeover_confirmation.ready.title' | translate:{ givenName: documents[0]?.createdBy }"></l7-text-block>
            </div>
        </ng-container>
    </div>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div *ngIf="!this.ready"
             fxFlex="100"
             fxFlex.gt-md="50"
             fxLayout="column"
             fxLayoutGap="16px">
            <h2>{{ 'contract_management.takeover_confirmation.signing_link.title' | translate }}</h2>
            <div fxFill
                 fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'contract_management.takeover_confirmation.signing_link.link' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           name="link"
                           readonly
                           [attr.test-id]="'AcknowledgementLinkInput'"
                           [disabled]="(this.presenter.data | async).link.invalid_at || this.overview?.ready?.sent_to_accounting || this.ready ? true : false"
                           [class]="(this.presenter.data | async).link.invalid_at ? 'strikethrough' : ''"
                           [value]="(this.presenter.data | async).link.link">
                    <button matSuffix
                            mat-icon-button
                            [attr.test-id]="'AcknowledgementLinkCopyToClipboardButton'"
                            [disabled]="(this.presenter.data | async).link.invalid_at || this.overview?.ready?.sent_to_accounting || this.ready ? true : false"
                            [cdkCopyToClipboard]="(this.presenter.data | async).link.link"
                            (cdkCopyToClipboardCopied)="this.onLinkCopied()">
                        <mat-icon matSuffix
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Copy'"></mat-icon>
                    </button>
                    <mat-hint class="disabled" *ngIf="(!((this.presenter.data | async).link.invalid_at)) && ((this.presenter.data | async).link.link ? true : false)">
                        {{ 'contract_management.takeover_confirmation.signing_link.expires_at' | translate:{ date: (this.presenter.data | async).link.expires_at | date: 'short' } }}
                    </mat-hint>
                    <mat-hint class="disabled" *ngIf="((this.presenter.data | async).link.invalid_at)">
                        {{ 'contract_management.takeover_confirmation.signing_link.invalid_at' | translate:{ date: (this.presenter.data | async).link.invalid_at | date:'short' } }}
                    </mat-hint>
                </mat-form-field>
                <div *ngIf="!this.ready"
                     fxLayout="row"
                     fxLayoutAlign="end"
                     fxLayoutGap="16px">
                    <button *ngIf="(this.presenter.data | async).link.link && !((this.presenter.data | async).link.invalid_at)"
                            mat-flat-button
                            [attr.test-id]="'AcknowledgementDeactivateLinkButton'"
                            [color]="'accent'"
                            [disabled]="this.overview?.ready?.sent_to_accounting"
                            (click)="this.presenter.onDeactivateLink()">{{ 'contract_management.takeover_confirmation.signing_link.deactivate' | translate }}</button>
                    <button *ngIf="(this.presenter.data | async).link.invalid_at || (!((this.presenter.data | async).link.invalid_at) && !((this.presenter.data | async).link.link))"
                            mat-flat-button
                            [attr.test-id]="'AcknowledgementGenerateLinkButton'"
                            [color]="'accent'"
                            [disabled]="this.overview?.ready?.sent_to_accounting"
                            (click)="this.presenter.onGenerateLink()">{{ 'contract_management.takeover_confirmation.signing_link.generate' | translate }}</button>
                </div>
            </div>
        </div>
        <div fxFlex="100"
             fxFlex.gt-md="50"
             fxLayout="column"
             fxLayoutGap="16px">
            <h2>{{ 'contract_management.takeover_confirmation.object.title' | translate }}</h2>
            <h4>{{ 'contract_management.takeover_confirmation.object.sub_title' | translate }}</h4>
            <form [formGroup]="this.form"
                  fxFill
                  fxFlex="50"
                  fxFlex.gt-md-lg="100"
                  fxLayout="column"
                  fxLayoutGap="20px"
                  (submit)="this.presenter.onSave(this.form)">
                <div fxLayoutGap="0"
                     fxLayoutGap.gt-sm="16px"
                     fxLayout="column"
                     fxLayout.gt-sm="row">
                    <span>{{'contract_management.takeover_confirmation.object.is_a_chassis_number' | translate}}</span>
                    <mat-radio-group formControlName="isChassisNumber"
                                     (change)="changeSerialNumberLabel($event)">
                        <mat-radio-button [value]="false">Nein</mat-radio-button>
                        <mat-radio-button [value]="true">Ja</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div fxLayoutGap="0"
                     fxLayoutGap.gt-sm="16px"
                     fxLayout="column"
                     fxLayout.gt-sm="row">
                    <mat-form-field fxFlex
                                    appearance="outline">
                        <mat-label>{{ 'contract_management.takeover_confirmation.object.object_name' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="name"
                               [attr.test-id]="'AcknowledgementNameInput'">
                    </mat-form-field>

                    <mat-form-field fxFlex
                                    appearance="outline">
                        <mat-label>{{ labelSerialNumber | async }}
                        </mat-label>
                        <input matInput
                               l7MarkAsTouched
                               type="text"
                               formControlName="serialNumber"
                               [attr.test-id]="'AcknowledgementSerialNumberInput'">
                        <mat-error *ngIf="form.controls.serialNumber.invalid && form.controls.serialNumber.hasError('serialNumber')">
                            I, O, Q nicht zulässig
                        </mat-error>
                        <mat-error *ngIf="(form.controls.serialNumber.getError('minlength') ||
                              form.controls.serialNumber.getError('maxlength')) && !form.controls.serialNumber.hasError('serialNumber')">
                            17 Zeichen nötig
                        </mat-error>
                    </mat-form-field>
                    <l7-date-picker fxFlex
                                    [label]="'contract_management.takeover_confirmation.object.creation_date'"
                                    [controlName]="'acknowledgementDate'"
                                    [uniqueId]="'AcknowledgementDateInput'"
                                    [formFieldTooltip]="'contract_management.takeover_confirmation.object.creation_date_tooltip'"
                                    [formFieldTooltipClass]="'tooltip-info'"
                                    [formFieldTooltipPosition]="'above'"
                                    [minDate]="(this.presenter.data | async).purchaseEntry.creation_date"
                                    [parentFormGroup]="form">
                    </l7-date-picker>
                </div>
                <div fxLayoutGap="0"
                     fxLayoutGap.gt-sm="16px"
                     fxLayout="column"
                     fxLayout.gt-sm="row">
                    <mat-form-field fxFlex
                                    appearance="outline">
                        <mat-label>{{ 'contract_management.takeover_confirmation.object.condition' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="condition"
                               [attr.test-id]="'AcknowledgementConditionInput'">
                    </mat-form-field>
                    <mat-form-field fxFlex
                                    appearance="outline">
                        <mat-label>
                            {{ 'contract_management.takeover_confirmation.object.year_of_construction' | translate }}
                        </mat-label>
                        <mat-select formControlName="yearOfConstruction"
                                    [attr.test-id]="'AcknowledgementYearOfConstructionSelect'">
                            <mat-option *ngFor="let year of this.years; trackBy:yearTrackByIndex"
                                        [attr.test-id]="'AcknowledgementYearOfConstructionSelect' + year"
                                        [value]="year">{{ year }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex
                                    appearance="outline">
                        <mat-label>{{ 'contract_management.takeover_confirmation.object.purchase_price' | translate }}</mat-label>
                        <input formControlName="purchasePrice"
                               matInput
                               type="text"
                               currencyMask
                               l7SelectOnDblClick
                               l7MarkAsTouched
                               [attr.test-id]="'AcknowledgementPurchasePriceInput'"
                               (keyup)="this.presenter.onPurchasePriceChanged(this.form.controls.purchasePrice.value)">
                        <mat-error *ngIf="form.controls.purchasePrice.invalid && form.controls.purchasePrice.hasError('percentage') && form.controls.purchasePrice.getError('percentage') as error">
                            Der maximale Kaufpreis beträgt {{error.max | number}} € (110%)
                        </mat-error>
                        <mat-error *ngIf="form.controls.purchasePrice.invalid && form.controls.purchasePrice.hasError('min') && form.controls.purchasePrice.getError('min') as error">
                            Der minimale Kaufpreis beträgt {{error.min | number}} €
                        </mat-error>
                    </mat-form-field>
                </div>
                <l7-banner *ngIf="(this.presenter.hasPurchasePriceChanges | async)"
                           [appearance]="'textual'"
                           [color]="'warn'"
                           [icon]="'Alert_Triangle'"
                           [iconFontSet]="'l7'"
                           [content]="'Der Kaufpreis wurde geändert: Bitte bestätigen Sie die neuen Werte durch Speichern.'"></l7-banner>
                <ng-container *ngIf="this.presenter.hasAnyInstalment | async">
                    <div fxLayoutGap="0"
                         fxLayoutGap.gt-sm="16px"
                         fxLayout="column"
                         fxLayout.gt-sm="row">
                        <mat-form-field fxFlex="33"
                                        appearance="outline"
                                        *ngIf="this.presenter.hasDownPayment | async">
                            <mat-label>{{ ('contract_management.takeover_confirmation.object.down_payment' | translate) }}</mat-label>
                            <input matInput
                                   type="text"
                                   currencyMask
                                   l7SelectOnDblClick
                                   formControlName="downPayment"
                                   [attr.test-id]="'AcknowledgementDownPaymentInput'">
                        </mat-form-field>
                        <mat-form-field fxFlex="33"
                                        appearance="outline"
                                        *ngIf="this.presenter.hasResidualValue | async">
                            <mat-label>{{ ('contract_management.takeover_confirmation.object.residual_value' | translate) }}</mat-label>
                            <input matInput
                                   type="text"
                                   currencyMask
                                   l7SelectOnDblClick
                                   formControlName="residualValue"
                                   [attr.test-id]="'AcknowledgementResidualValueInput'">
                        </mat-form-field>
                        <mat-form-field fxFlex="33"
                                        appearance="outline"
                                        *ngIf="this.presenter.hasFirstInstalment | async">
                            <mat-label>{{ ('contract_management.takeover_confirmation.object.MIETKAUF_first_instalment' | translate) }}</mat-label>
                            <input matInput
                                   type="text"
                                   currencyMask
                                   l7SelectOnDblClick
                                   formControlName="firstInstalment"
                                   [attr.test-id]="'AcknowledgementFirstInstalmentInput'">
                        </mat-form-field>
                        <mat-form-field fxFlex="33"
                                        appearance="outline">
                            <mat-label>{{ this.overview.contract_type === 'MIETKAUF' ? ('contract_management.takeover_confirmation.object.MIETKAUF_instalment' | translate) : ('contract_management.takeover_confirmation.object.instalment' | translate) }}</mat-label>
                            <input matInput
                                   type="text"
                                   currencyMask
                                   l7SelectOnDblClick
                                   formControlName="instalment"
                                   [attr.test-id]="'AcknowledgementInstalmentInput'">
                        </mat-form-field>
                        <mat-form-field fxFlex="33"
                                        appearance="outline"
                                        *ngIf="this.presenter.hasLastInstalment | async">
                            <mat-label>{{ ('contract_management.takeover_confirmation.object.MIETKAUF_last_instalment' | translate) }}</mat-label>
                            <input matInput
                                   type="text"
                                   required
                                   currencyMask
                                   l7SelectOnDblClick
                                   formControlName="lastInstalment"
                                   [attr.test-id]="'AcknowledgementLastInstalmentInput'">
                        </mat-form-field>

                        <mat-form-field fxFlex="33"
                                        appearance="outline">
                            <mat-label>
                                {{ (this.presenter.data | async).expenses?.insurance_type === 'PRO100' ? ('Neuer Wert 100Pro' | translate) : ('Neuer Versicherungswert' | translate) }}
                            </mat-label>
                            <input matInput
                                   type="text"
                                   currencyMask
                                   l7SelectOnDblClick
                                   formControlName="insuranceValue"
                                   [attr.test-id]="'AcknowledgementInsuranceValueInput'">
                        </mat-form-field>

                    </div>
                </ng-container>
                <div fxLayout="row"
                     fxLayoutAlign="end"
                     fxLayoutGap="16px">
                    <button type="submit"
                            mat-flat-button
                            [color]="'accent'"
                            [attr.test-id]="'AcknowledgementSaveButton'"
                            [disabled]="this.overview?.ready?.sent_to_accounting || this.overview?.ready?.acknowledgement_complete">
                        {{ 'global.save_changes' | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div *ngIf="!this.ready">
            <p>
                <span>Oder unterschriebene Übernahmebestätigung nach Angabe der Daten als </span>
                <a class="link"
                   [attr.test-id]="'AcknowledgementUploadButton'"
                   (click)="this.presenter.onPaperSigning()">PDF hochladen</a>
                <span>.</span>
            </p>
        </div>
    </div>
</div>
