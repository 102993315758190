// #region Imports

import { IUserInfoDto } from '@abcfinlab/api/login';
import { SESSION_AVAILABLE_TOKEN, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { TileRegistry } from '@abcfinlab/presentation';
import { TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { FeedbackService } from '../Services/FeedbackService';
import { FeedbackWidgetView } from '../Views/Widgets/FeedbackWidgetView';

// #endregion

/**
 * The `FeedbackModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslationModule,
        TextBlockModule,
        MatCardModule,
        TextBlockModule,
        MatButtonModule,
    ],
    exports: [
        FeedbackWidgetView,
    ],
    declarations: [
        FeedbackWidgetView,
    ],
})
export class FeedbackModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `FeedbackModule` class.
     *
     * @public
     */
    public constructor(tileRegistry: TileRegistry, feedbackService: FeedbackService,
        @Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>) {
        combineLatest([sessionAvailable, userInfo, feedbackService.hasWidget])
            .pipe(first())
            .subscribe(([_, user, hasWidget]) => {
                if (user && hasWidget) {
                    tileRegistry.register({
                        key: 'FEEDBACK',
                        order: 50,
                        columnSpan: { min: 2, max: 2 },
                        rowSpan: { min: 1, max: 1 },
                        views: {
                            front: FeedbackWidgetView,
                        },
                    });
                }
            });
    }

    // #endregion

}
