// #region Imports

import { VerificationService } from '@abcfinlab/api/global';
import { FinanceAdminService, IRetailerUserResponseDto, IUserRequestDto } from '@abcfinlab/api/retailer';
import { IUserGroupRetailerDto } from '@abcfinlab/auth';
import { ControlsOf, Validators as CoreValidators, DeepRequired, FormChangesObserver, FormValidator, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { RETAILER_EDIT_ROUTE_PATH } from '../Routing/RoutePaths';
import { Validators as AdministrationValidators } from '../Validators/Validators';

// #endregion

/**
 * The presenter of the {@link EditRetailerUserView} view.
 *
 * @internal
 */
@Injectable()
export class EditRetailerUserViewPresenter {

    // #region Fields

    private readonly _router: Router;
    private readonly _route: ActivatedRoute;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _financeAdminService: FinanceAdminService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _formValidator: FormValidator;
    private readonly _formChangesObserver: FormChangesObserver;
    private readonly _toastService: ToastService;
    private readonly _retailerUserSubject: BehaviorSubject<IRetailerUserResponseDto> = new BehaviorSubject<IRetailerUserResponseDto>(null);
    private readonly _retailerIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _retailerUserIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _userNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _isLastAdminSubject: BehaviorSubject<boolean>;

    private readonly _form: FormGroup<ControlsOf<DeepRequired<IUserRequestDto>>>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `EditRetailerUserViewPresenter` class.
     *
     * @public
     */
    public constructor(router: Router, route: ActivatedRoute, busyBoxService: BusyBoxService, messageBoxService: MessageBoxService,
        financeAdminService: FinanceAdminService, translationFacade: TranslationFacade, formValidator: FormValidator, formChangesObserver: FormChangesObserver,
        toastService: ToastService, verificationService: VerificationService) {
        this._router = router;
        this._route = route;
        this._busyBoxService = busyBoxService;
        this._messageBoxService = messageBoxService;
        this._financeAdminService = financeAdminService;
        this._translationFacade = translationFacade;
        this._formValidator = formValidator;
        this._formChangesObserver = formChangesObserver;
        this._toastService = toastService;
        this._isLastAdminSubject = new BehaviorSubject(false);
        this._form = new FormGroup<ControlsOf<DeepRequired<IUserRequestDto>>>({
            email: new FormControl('', [Validators.required, CoreValidators.email()], AdministrationValidators.emailInvalidAsync(verificationService)),
            familyName: new FormControl('', Validators.required),
            givenName: new FormControl('', Validators.required),
            groups: new FormControl<Array<any>>([IUserGroupRetailerDto.RetailerAdmin], Validators.required),
            mobileNumber: new FormControl('', [CoreValidators.phoneNumber()]),
            phoneNumber: new FormControl('', [CoreValidators.phoneNumber()]),
            active: new FormControl<boolean>(true),
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `userGroup` property.
     * A Static helper property to bind enums in the view
     *
     * @public
     * @readonly
     */
    public get userGroup(): typeof IUserGroupRetailerDto {
        return IUserGroupRetailerDto;
    }

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup {
        return this._form;
    }

    /**
     * Returns the `retailerUser` property.
     *
     * @public
     * @readonly
     */
    public get retailerUser(): Observable<IRetailerUserResponseDto> {
        return this._retailerUserSubject.asObservable();
    }

    /**
     * Returns the `userName` property.
     *
     * @public
     * @readonly
     */
    public get userName(): Observable<string> {
        return this._userNameSubject.asObservable();
    }

    /**
     * Returns the `isLastAdmin` property.
     *
     * @public
     * @readonly
     */
    public get isLastAdmin(): Observable<boolean> {
        return this._isLastAdminSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.initializeQueryParams();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onSubmit(cancel: boolean = false): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind Sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Änderungen werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.translate('global.yes'),
                    no: this._translationFacade.translate('global.no'),
                },
            }).subscribe((result) => {
                if (result === MessageBoxResult.Yes) {
                    void this._router.navigate([`../../../${RETAILER_EDIT_ROUTE_PATH}`], {
                        relativeTo: this._route,
                        queryParams: {
                            id: this._retailerIdSubject.value,
                        },
                    });
                }
            });

            return;
        }

        const errors = this._formValidator
            .validate(this._form)
            .errors(this._form);

        // ignore email emailInvaid error because it is only a warning.
        if ((errors.length - (errors.some(x => x.name === 'email' && x.errors.emailInvaid) ? 1 : 0)) > 0) {
            this._toastService.show(this._translationFacade.instant('administration.retailers.create.toast.form.error'), 'danger');
        } else {
            once(this._busyBoxService.show(undefined, this._translationFacade.instant('global.busy'),
                this._financeAdminService.updateRetailerUser({
                    retailerId: this._retailerUserSubject.value.retailerId,
                    body: { ...this._form.getRawValue(), ...{ id: this._retailerUserIdSubject.value } },
                }),
                { id: 'saveRetailerUser' },
            ), () => {
                this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.success'), 'success');
                void this._router.navigate([`../../../${RETAILER_EDIT_ROUTE_PATH}`], {
                    relativeTo: this._route,
                    queryParams: {
                        id: this._retailerUserSubject.value.retailerId,
                        tabIndex: 2,
                    },
                });
            }, (error) => {
                this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.error'), 'danger');
                throwError(error);

                // handle specific error code and reload the user... (status: 426)
                this.initializeRetailerUser(this._retailerUserIdSubject.value);
            });
        }
    }

    /**
     * @private
     */
    private initializeQueryParams(): void {
        once(this._route.queryParams, (x) => {
            if (!x.retailerId && !x.retailerUserId) {
                throw new Error('The \'retailerId\' and/or \'retailerUserId\' is required.');
            }

            this._retailerIdSubject.next(x.retailerId);
            this._retailerUserIdSubject.next(x.retailerUserId);
            this.initializeRetailerUser(x.retailerUserId);
            this.initializeIsAdminUser();
        });
    }

    /**
     * @private
     */
    private initializeRetailerUser(id: string): void {
        once(this._busyBoxService.show(undefined, undefined,
            this._financeAdminService.getRetailerUserById({ userId: this._retailerUserIdSubject.value }), { id: 'initializeRetailerUser' }), (x) => {
            this._retailerUserSubject.next(x);
            this._userNameSubject.next(`${x.givenName} ${x.familyName}`);
            this._form.patchValue(x);
        }, (error) => {
            this._messageBoxService.show('Nicht gefunden', error.error.error_description, MessageBoxButton.OK, {
                labels: {
                    ok: 'OK',
                },
            });
        });
    }

    private initializeIsAdminUser(): void {
        once(this._busyBoxService.show(undefined, undefined, this._financeAdminService.getAdminUsers({
            retailerId: this._retailerIdSubject.value,
        }).pipe(
            map((x) => {
                const countWithoutMe = x.filter(x => !x.includes(this._retailerUserIdSubject.value)).length;
                const isLast = countWithoutMe >= 1 ? false : true;
                return isLast;
            }),
        ), { id: 'getAdminUsers' }), x => this._isLastAdminSubject.next(x));
    }

    // #endregion

}
