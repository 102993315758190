import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PrivacyNoticeComponent } from './pages/privacy-notice/privacy-notice.component';

@NgModule({
    declarations: [PrivacyNoticeComponent, LandingPageComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class PublicModule {
}
