/* apps/l7/src/app/public/pages/privacy-notice/privacy-notice.component.scss */
:host mat-expansion-panel {
  color: #333333;
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}
:host mat-expansion-panel mat-panel-title {
  color: #385463;
}
:host mat-expansion-panel a {
  margin: 0 5px;
}
/*# sourceMappingURL=privacy-notice.component.css.map */
