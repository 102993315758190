<ng-container *cdkBreakpoint="let bp">
    <l7-dialog-header [title]="'Übernahmebestätigung hochladen' | translate"
                      [closeable]="true"
                      (closed)="presenter.onClose(true)"></l7-dialog-header>
    <mat-dialog-content>
        <form #form="ngForm"
              fxFlex="100"
              [formGroup]="presenter.form"
              (ngSubmit)="presenter.onSubmit(false)">
            <mat-stepper #stepper
                         [orientation]="bp('lt-md') ? 'vertical' : 'horizontal'">
                <mat-step [label]="'Angaben zur Auslieferung' | translate"
                          [state]="'number'"
                          [stepControl]="presenter.form.controls.general">
                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <mat-card-header>
                            <l7-text-block matCardTitle
                                           [wrap]="true"
                                           [text]="'Angaben zur Auslieferung' | translate"></l7-text-block>
                            <l7-text-block matCardSubtitle
                                           [wrap]="true"
                                           [text]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceSubTitle' | translate:
                                           {maxPercentage: (presenter.calculationSettings | async)?.leasingQuoteCalculationProperties.retailer.acknowledgement.maxObjectValueChangePercent}">
                            </l7-text-block>
                        </mat-card-header>
                        <mat-card-content fxLayout="column"
                                          fxLayoutGap="16px"
                                          [formGroupName]="'general'">
                            <mat-slide-toggle #toggle
                                              (change)="presenter.onPurchasePriceChangeToggle($event)"
                                              [attr.test-id]="'purchasePriceChanged'"
                                              [formControlName]="'purchasePriceChanged'">
                                <l7-text-block [text]="'Der Nettokaufpreis hat sich geändert' | translate"></l7-text-block>
                            </mat-slide-toggle>
                            <div fxLayout="row" class="without-margin">
                                <mat-form-field fxFlex="40"
                                                fxFlex.lt-md="100"
                                                appearance="outline">
                                    <mat-label>Nettokaufpreis</mat-label>
                                    <input matInput
                                           (keyup)="presenter.purchasePriceSubject.next($event)"
                                           currencyMask
                                           l7SelectOnDblClick
                                           l7MarkAsTouched
                                           [attr.test-id]="'purchasePrice'"
                                           [formControlName]="'purchasePrice'"
                                           [readonly]="!toggle.checked">

                                    <mat-spinner matSuffix *ngIf="(presenter.isBusyCalculating | async)" [diameter]="27"></mat-spinner>
                                    <mat-error *ngIf="presenter.form.controls.general.controls.purchasePrice.hasError('percentage') || presenter.form.controls.general.controls.purchasePrice.hasError('max')">
                                        <p>{{ 'Der Nettokaufpreis ist zu hoch.' | translate }}</p>
                                    </mat-error>
                                    <mat-error *ngIf="presenter.form.controls.general.controls.purchasePrice.hasError('min')">
                                        <p>{{ 'Der Nettokaufpreis ist zu niedrig.' | translate }}</p>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="(presenter.isPurchasePriceChanged | async) && (presenter.calculationHasError | async) === false && !presenter.form.controls.general.controls.purchasePrice.invalid && (presenter.isBusyCalculating | async) === false">
                                <l7-banner [color]="'warn'"
                                           [appearance]="'outlined'"
                                           [iconFontSet]="'l7'"
                                           [icon]="'Alert_Triangle'"
                                           [content]="presenter.warningMessageIfPriceChanged | async">
                                </l7-banner>
                            </ng-container>
                            <ng-container *ngIf="(presenter.isPurchasePriceChanged | async) && (presenter.calculationHasError | async) === true && (presenter.isBusyCalculating | async) === false">
                                <l7-banner [color]="'danger'"
                                           [appearance]="'outlined'"
                                           [iconFontSet]="'l7'"
                                           [icon]="'Alert_Triangle'"
                                           [content]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceChangedError' | translate">
                                </l7-banner>
                            </ng-container>

                            <mat-checkbox [formControlName]="'acknowledgementConfirmed'">
                                <l7-text-block [wrap]="true"
                                               [text]="'Ich bestätige, dass die Übernahmebestätigung vom Kunden ohne Einschränkungen unterzeichnet und das Objekt wie bestellt ausgeliefert wurde.' | translate"></l7-text-block>
                            </mat-checkbox>

                            <div fxLayout="row">
                                <l7-date-picker fxFlex="40" fxFlex.lt-md="100"
                                                [label]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubInput'"
                                                [controlName]="'acknowledgementDate'"
                                                [requiredErrorMessage]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubInputErrorMandatory'"
                                                [minDateErrorMessage]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubInputErrorMinDate'"
                                                [uniqueId]="'acknowledgementDate'"
                                                [parentFormGroup]="presenter.form.get('general')">
                                </l7-date-picker>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-step>
                <mat-step [label]="'Angaben zum Objekt' | translate"
                          [state]="'number'"
                          [stepControl]="presenter.form.controls.choice">
                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <mat-card-header>
                            <l7-text-block matCardTitle
                                           [text]="'Angaben zu den Objekten' | translate"></l7-text-block>
                            <l7-text-block matCardSubtitle
                                           [text]="'Bitte wählen Sie eine der drei Optionen, um uns die Seriennummer(n) mitzuteilen' | translate"></l7-text-block>
                        </mat-card-header>
                        <mat-card-content fxLayout="column"
                                          fxLayoutGap="32px">
                            <mat-radio-group fxLayout="column"
                                             fxLayoutGap="16px"
                                             [attr.test-id]="'choice'"
                                             [formControlName]="'choice'">
                                <mat-radio-button [value]="'manual'">
                                    <l7-text-block [wrap]="true"
                                                   [text]="'Ich trage die Geräte-/Seriennummern im nächsten Schritt ein' | translate"></l7-text-block>
                                </mat-radio-button>
                                <mat-radio-button [value]="'includeInDelivery'">
                                    <l7-text-block [wrap]="true"
                                                   [text]="'Die erforderliche(n) Geräte-/Seriennummer(n) sind auf der Übernahmebestätigung enthalten' | translate"></l7-text-block>
                                </mat-radio-button>
                                <mat-radio-button [value]="'extraDocument'">
                                    <l7-text-block [text]="'Die erforderliche(n) Geräte-/ Seriennummer(n) können der hochzuladenden Anlage entnommen werden'  | translate"></l7-text-block>
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-card-content>
                    </mat-card>
                </mat-step>

                <mat-step [label]="'Abschluss' | translate"
                          [state]="'number'"
                          [stepControl]="presenter.form.controls.files">
                    <div fxLayout="column"
                         fxLayoutGap="16px">
                        <mat-card appearance="outlined" *ngIf="presenter.choice.value === 'manual'"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <l7-text-block matCardTitle
                                               [wrap]="true"
                                               [text]="'Seriennummern eingeben' | translate"></l7-text-block>
                                <l7-text-block matCardSubtitle
                                               [wrap]="true"
                                               [text]="'Bitte teilen Sie uns die Seriennummernn der im Leasingvertrag enthaltenen Objekte mit' | translate"></l7-text-block>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'files'">
                                <ng-container [formArrayName]="'serialNumbers'">
                                    <div *ngFor="let sn of presenter.serialNumbers.controls; let i = index"
                                         fxLayout="row"
                                         fxLayout.lt-sm="column"
                                         fxLayoutGap="16px"
                                         fxLayoutAlign="flex-start baseline">
                                        <ng-container [formGroupName]="i">
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'Position' | translate }}</mat-label>
                                                <input matInput
                                                       type="number"
                                                       [attr.test-id]="'position' + i"
                                                       [formControlName]="'position'">
                                                <mat-error *ngIf="sn.controls.position.hasError('required')">
                                                    <p>{{ 'Die Position ist erforderlich.' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'Objektbezeichnung' | translate }}</mat-label>
                                                <input matInput
                                                       type="text"
                                                       [attr.test-id]="'objectDescription' + i"
                                                       [formControlName]="'objectDescription'">
                                                <mat-error *ngIf="sn.controls.objectDescription.hasError('required')">
                                                    <p>{{ 'Die Objektbezeichnung ist erforderlich.' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{'Baujahr' | translate}}</mat-label>
                                                <mat-select [formControlName]="'yearOfManufacture'" [attr.test-id]="'yearOfManufacture' + i">
                                                    <mat-option *ngFor="let year of presenter.years"
                                                                [value]="year">{{ year }}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="sn.controls.yearOfManufacture.hasError('required')">
                                                    <p>{{ 'Das Baujahr ist erforderlich.' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'Geräte-/Seriennummer' | translate }}</mat-label>
                                                <input matInput
                                                       type="text"
                                                       [attr.test-id]="'serialNumber' + i"
                                                       [formControlName]="'serialNumber'">
                                                <mat-error *ngIf="sn.controls.serialNumber.hasError('required')">
                                                    <p>{{ 'Die Geräte-/Seriennummer ist erforderlich.' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="sn.controls.serialNumber.hasError('maxlength')">
                                                    <p>{{ 'Die Geräte-/Seriennummer ist zu lang.' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <button mat-icon-button
                                                    [attr.test-id]="'deleteSerialNumber' + i"
                                                    (click)="presenter.onDeleteSerialNumber(sn.getRawValue().id, i)">
                                                <mat-icon [fontSet]="'l7'"
                                                          [fontIcon]="'Trash'">
                                                </mat-icon>
                                            </button>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <button mat-button
                                            [color]="'primary'"
                                            type="button"
                                            [attr.test-id]="'btnAddSerialNumber'"
                                            (click)="presenter.onAddSerialNumber()">
                                        <mat-icon [fontSet]="'l7'"
                                                  [fontIcon]="'Plus'"></mat-icon>
                                        {{'Weitere Seriennummer hinzufügen' | translate}}
                                    </button>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined" class="mat-card-outlined">
                            <mat-card-header>
                                <l7-text-block matCardTitle
                                               [wrap]="true"
                                               [text]="'Übernahmebestätigung hochladen' | translate"></l7-text-block>
                                <l7-text-block matCardSubtitle
                                               [wrap]="true"
                                               [text]="'Bitte laden Sie die unterschriebene Übernahmebestätigung hoch' | translate"></l7-text-block>
                            </mat-card-header>
                            <mat-card-content fxLayout="column">
                                <l7-file-uploader [allowedFileTypes]="['pdf']"
                                                  [maxFiles]="1"
                                                  [orientation]="'vertical'"
                                                  [fileListClass]="'new-style'"
                                                  [attr.test-id]="'uploadAcknowledgement'"
                                                  (filesChanged)="presenter.onFilesChanged('acknowledgement', $event)"></l7-file-uploader>
                                <mat-error class="mat-error-standalone"
                                           *ngIf="presenter.form.controls.files.controls.acknowledgement.hasError('required')">
                                    <p>{{ 'Die Übernahmebestätigung ist erforderlich.' | translate }}</p>
                                </mat-error>
                                <mat-hint class="mat-hint-standalone">
                                    <l7-text-block [wrap]="true"
                                                   [text]="'Folgende Dateiformate sind zulässig: PDF. Die zulässige Dateigröße beträgt 10 MB.' | translate"></l7-text-block>
                                </mat-hint>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined" *ngIf="presenter.choice.value === 'extraDocument'"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <l7-text-block matCardTitle
                                               [wrap]="true"
                                               [text]="'Anlage mit Seriennummern hochladen' | translate"></l7-text-block>
                                <l7-text-block matCardSubtitle
                                               [wrap]="true"
                                               [text]="'Bitte laden Sie die Anlage mit den Seriennummern hoch' | translate"></l7-text-block>
                            </mat-card-header>
                            <mat-card-content fxLayout="column">
                                <l7-file-uploader [allowedFileTypes]="['pdf']"
                                                  [maxFiles]="1"
                                                  [orientation]="'vertical'"
                                                  [fileListClass]="'new-style'"
                                                  [attr.test-id]="'uploadSerialNumber'"
                                                  (filesChanged)="presenter.onFilesChanged('serialNumber', $event)"></l7-file-uploader>
                                <mat-error class="mat-error-standalone"
                                           *ngIf="presenter.form.controls.files.controls.serialNumber.hasError('required')">
                                    <p>{{ 'Die Seriennummern sind erforderlich.' | translate }}</p>
                                </mat-error>
                                <mat-hint class="mat-hint-standalone">
                                    <l7-text-block [wrap]="true"
                                                   [text]="'Folgende Dateiformate sind zulässig: PDF. Die zulässige Dateigröße beträgt 10 MB.' | translate"></l7-text-block>
                                </mat-hint>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-step>
            </mat-stepper>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button
                color="primary"
                *ngIf="stepper.selectedIndex !== 0"
                [attr.test-id]="'btnPrevious' + stepper.selectedIndex"
                (click)="stepper.previous()">{{'Zurück' | translate}}</button>
        <button mat-flat-button
                color="primary"
                *ngIf="stepper.selectedIndex !== 2"
                [attr.test-id]="'btnNext' + stepper.selectedIndex"
                (click)="stepper.next()">{{'Weiter' | translate}}</button>
        <button mat-flat-button
                color="primary"
                [attr.test-id]="'btnSubmit'"
                *ngIf="stepper.selectedIndex === 2"
                (click)="form.onSubmit($event)">{{'Abschließen' | translate}}</button>
    </mat-dialog-actions>
</ng-container>
